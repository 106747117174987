import React from "react"

import { useRouter } from "next/router"

const Subdrawer = ({
  open,
  next,
  className,
  close,
  data,
  exit,
  handleSelectSubMenu,
}) => {
  const Router = useRouter()

  const handleExit = () => {
    Router.push("/")
    setTimeout(() => exit(), 1000)
  }

  const handleClick = (item) => {
    handleSelectSubMenu(item, next)
  }

  return (
    <div
      className={`side-drawer-mobile d-lg-none ${className} ${
        open ? "open" : ""
      }`}
    >
      <div className="main-drawer-wrapper">
        <div className="with-padding">
          <div className="nav-back">
            <div className="nav-item">
              <div className="nav-link" onClick={close}>
                <i className="ail ai-arrow-left" />
              </div>
            </div>
          </div>
          <h5
            className="subtitle"
            onClick={() => handleClick({ slug: data.slug, id: data.id })}
          >
            {data?.title}
          </h5>

          <div className="side-drawer-content">
            <ul className="nav-middle">
              {data?.children?.map((item) => (
                <li
                  key={item.id}
                  className="nav-item"
                  onClick={() => handleClick(item)}
                >
                  <div
                    className={`nav-link ${
                      item.children?.length > 0 ? "w-arrow" : ""
                    }`}
                  >
                    {item.title}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Subdrawer
