//Utils
import Cookies from "js-cookie"
import { staticApollo } from "lib/apollo"
import {
  Q_CATALOGS,
  Q_PRODUCT,
  Q_MY_WISHLIST,
  Q_LOCATION,
  Q_SEARCH_CATALOGS,
  Q_PAGES,
  Q_MY_CART,
  Q_BANNERS,
  Q_CATALOGS_BY_CATEGORY,
  Q_CART_WITH_SHIPPING,
  Q_MY_ORDERS,
  Q_ORDER_DETAILS,
  Q_FAQ,
  Q_FAQ_DESC,
  Q_FAQ_CAT,
  Q_STOCK,
  Q_STORE_INFO,
} from "lib/graphql"

/**
 *
 * @param {*} values
 * @param {*} token
 * @returns
 */
export const F_Q_CATALOGS = async (values, token) => {
  //Define client;
  let client
  let fetch
  if (token) client = staticApollo(token)
  else client = staticApollo(Cookies.get(`${process.env.GUEST_TOKEN}`))

  if (values)
    fetch = client.query({
      query: Q_CATALOGS,
      variables: { ...values },
      fetchPolicy: "no-cache",
    })
  else fetch = client.query({ query: Q_CATALOGS })

  try {
    let { data, errors } = await fetch
    if (data) return { data, errors: null }
    else return { data: null, errors }
  } catch (error) {
    return { data: null, errors: error }
  }
}

/**
 *
 * @param {*} values
 * @param {*} token
 * @returns
 */
export const F_Q_CATALOGS_CATEGORY = async (values, token) => {
  //Define client;
  let client
  let fetch
  if (token) client = staticApollo(token)
  else client = staticApollo(Cookies.get(`${process.env.GUEST_TOKEN}`))

  if (values)
    fetch = client.query({
      query: Q_CATALOGS_BY_CATEGORY,
      variables: { ...values, limit: values.limit ?? 9 },
      fetchPolicy: "no-cache",
    })
  else fetch = client.query({ query: Q_CATALOGS })

  try {
    let { data, errors } = await fetch
    if (data) return { data, errors: null }
    else return { data: null, errors }
  } catch (error) {
    return { data: null, errors: error }
  }
}

/**
 *
 * @param {*} values
   values example { id: "1", lang: "en" }
 * @param {*} token
 * @returns
 */
export const F_Q_PRODUCT = async (values, token) => {
  //Define client
  let client = staticApollo(token)
  let fetch = client.query({
    query: Q_PRODUCT,
    variables: { ...values },
    fetchPolicy: "no-cache",
  })
  try {
    let { data, errors } = await fetch
    if (data) return { data, errors: null }
    else return { data: null, errors }
  } catch (error) {
    return { data: null, errors: error }
  }
}

export const F_Q_STOCK = async (values, token) => {
  if (token) {
    let client = staticApollo(token)
    try {
      let { data, errors } = await client.query({
        query: Q_STOCK,
        variables: { ...values },
      })
      if (data) return { data }
      else return { errors }
    } catch (error) {
      return { errors: error }
    }
  }
}

/**
 *
 * @param {*} values
   sample values: { lang: "en" String! }
 * @param {*} token
 * @returns
 */
export const F_Q_WISHLIST = async (values, token) => {
  if (token) {
    let client = staticApollo(token)
    try {
      let { data, errors } = await client.query({
        query: Q_MY_WISHLIST,
        variables: { ...values },
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      })
      if (data && data?.wishlists) return { data, errors: null }
      else return { data: null, errors }
    } catch (error) {
      return { data: null, errors: error }
    }
  } else {
    return { data: null, errors: { message: "NO TOKEN" } }
  }
}

export const F_Q_CARTS = async (values, token) => {
  if (token) {
    let client = staticApollo(token)
    try {
      let { data, errors } = await client.query({
        query: Q_MY_CART,
        variables: { ...values },
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      })
      if (data) return { data, errors: null }
      else return { data: null, errors }
    } catch (error) {
      return { data: null, errors: error }
    }
  } else {
    return { data: null, errors: { message: "NO TOKEN" } }
  }
}

export const F_Q_CARTS_SHIPPING = async (values, token) => {
  if (token) {
    let client = staticApollo(token)
    try {
      let { data, errors } = await client.query({
        query: Q_CART_WITH_SHIPPING,
        variables: { ...values },
      })
      if (data) return { data }
      else return { errors: errors }
    } catch (error) {
      return { errors: error }
    }
  } else {
    return { errors: { message: "NO TOKEN" } }
  }
}

/**
 *
 * @param {*} values
 * @param {*} token
 * @returns
 */
export const F_Q_LOCATION_ADDRESS = async (values, token) => {
  let accessToken = Cookies.get(`${process.env.ACCESS_TOKEN}`)
  let guestToken = Cookies.get(`${process.env.GUEST_TOKEN}`)

  let client

  if (token) client = staticApollo(token)
  else if (accessToken) client = staticApollo(accessToken)
  else client = staticApollo(guestToken)
  try {
    let { data, errors } = await client.query({
      query: Q_LOCATION,
      variables: { ...values },
      errorPolicy: "all",
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

/**
 *
 * @param {*} values
 * @param {*} token
 * @returns
 */
export const F_Q_SEARCH = async (values, token) => {
  let client

  if (token) client = staticApollo(token)
  else client = staticApollo(Cookies.get(`${process.env.GUEST_TOKEN}`))

  try {
    let { data, errors } = await client.query({
      query: Q_SEARCH_CATALOGS,
      variables: { ...values },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

export const F_Q_PAGES = async (values, token) => {
  let client

  if (token) client = staticApollo(token)
  else client = staticApollo(Cookies.get(`${process.env.GUEST_TOKEN}`))

  try {
    let { data, errors } = await client.query({
      query: Q_PAGES,
      variables: { ...values },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

/**
 *
 * @param {*} values
 * @param {*} token
 * @returns
 */
export const F_Q_BANNERS = async (values, token) => {
  let client
  if (token) client = staticApollo(token)
  else client = staticApollo(Cookies.get(`${process.env.GUEST_TOKEN}`))

  try {
    let { data, errors } = await client.query({
      query: Q_BANNERS,
      variables: { ...values },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

/**
 *
 * @param {*} values
   Sample Values: { lang: String!, page: Int }
 * @param {*} token
 */
export const F_Q_MY_ORDERS = async (values, token) => {
  let client
  if (token) client = staticApollo(token)
  else client = staticApollo(Cookies.get(`${process.env.ACCESS_TOKEN}`))
  try {
    let { data, errors } = await client.query({
      query: Q_MY_ORDERS,
      variables: { ...values },
    })

    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

export const F_Q_ORDERS_DETAIL = async (values, token) => {
  let client
  if (token) client = staticApollo(token)
  else client = staticApollo(Cookies.get(`${process.env.ACCESS_TOKEN}`))
  try {
    let { data, errors } = await client.query({
      query: Q_ORDER_DETAILS,
      variables: { ...values },
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

export const F_Q_FAQ = async (values, token, type) => {
  let varQ
  if (type === "desc") varQ = Q_FAQ_DESC
  else varQ = Q_FAQ_CAT
  let client = staticApollo(token)
  try {
    let { data, errors } = await client.query({
      query: varQ,
      variables: { ...values },
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

export const F_Q_STORE_INFO = async (values, token) => {
  let client = staticApollo(token)
  try {
    let { data, errors } = await client.query({
      query: Q_STORE_INFO,
      variables: { ...values },
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}
