import React from "react"

//Components
import { Modal, Button, Image, Input, DatePicker, Check } from "components/anti"
import { ModalBs } from "components/anti/modal/modal-bootstrap"

//Assets
import StockImg from "@public/img/sample/stock.png"
import DocumentImg from "@public/img/sample/document.png"
import ModalAuthImg from "@public/img/sample/modal-auth.png"

//Utils
import { useRouter } from "next/router"
import { F_M_LOGIN, F_M_REGISTER } from "lib/function"
import Cookies from "js-cookie"
import * as Yup from "yup"
import { Formik } from "formik"
import { useScrollAnim } from "components/hooks/hooks"

const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/

export const ModalLogin = ({ id, show, hide, callback, onClickReset }) => {
  const Router = useRouter()
  const locale = Router.locale
  const [trigger, anim] = useScrollAnim()

  const YupLoginValidation = Yup.object({
    email: Yup.string()
      .email(
        locale === "id" ? "Format email tidak valid" : "Invalid email format"
      )
      .required(locale === "id" ? "Email harus diisi" : "Email required"),
    password: Yup.string()
      .required(
        locale === "id" ? "Kata sandi harus diisi" : "Password required"
      )
      .matches(
        passwordRegex,
        locale === "id"
          ? "Password harus memiliki minimal 8 karakter dan minimal 1 angka"
          : "Password must have a minimum of 8 characters and at least 1 number"
      ),
  })

  //States
  const initialState = {
    email: "",
    password: "",
  }

  const [state, setState] = React.useState({
    loading: null,
    isError: null,
  })

  //Functions
  const handleLogin = async (values) => {
    let token = Cookies.get(`${process.env.GUEST_TOKEN}`)
    if (token) {
      setState((prev) => ({ ...prev, loading: true }))
      try {
        let { data, errors } = await F_M_LOGIN({
          username: values.email,
          password: values.password,
        })
        if (data) {
          let { access_token, refresh_token } = data?.login
          if (access_token) {
            Cookies.set(`${process.env.ACCESS_TOKEN}`, access_token, {
              secure: true,
              domain: process.env.BASE_DOMAIN,
            })
            Cookies.set(`${process.env.REFRESH_TOKEN}`, refresh_token, {
              secure: true,
              domain: process.env.BASE_DOMAIN,
            })
            window.location.reload()
          } else {
            setState((prev) => ({ ...prev, loading: false, isError: true }))
          }
        } else {
          //!NEED ERROR HANDLING
          setState((prev) => ({ ...prev, loading: false, isError: true }))
        }
      } catch (error) {
        //!NEED ERROR HANDLING
        setState((prev) => ({ ...prev, loading: false, isError: true }))
      }
    } else {
      alert("NO TOKEN")
    }
  }

  return (
    <ModalBs
      className="modal-md"
      id={id}
      isShowing={show}
      hide={() => {
        hide()
        setState((prev) => ({ ...prev, isError: false }))
      }}
      contentClassName="p-0 m-0"
    >
      {/* <div
        onClick={() => {
          hide()
          setState((prev) => ({ ...prev, isError: false }))
        }}
        className="close"
      >
        <i className="ail ai-times" />
      </div> */}
      <div className="modal-auth">
        <div className={`p-4 ${anim(1)}`} ref={trigger}>
          <h3 className="title">{locale === "id" ? "Masuk" : "Sign In"}</h3>
          <p className={`subtitle`}>
            {locale === "id"
              ? "Hanya untuk pengalaman belanja Anda yang lebih cepat dan mudah."
              : "Simply for your faster and easier shopping experience."}
          </p>
          <div className="alert alert-warning">
            {locale === "id"
              ? "Apabila anda sudah pernah memiliki akun di website kami yang sebelumnya silahkan update password anda melalui fitur 'Forgot Password / Tidak ingat kata sandi'"
              : "If you're already registered as our user on the previous website, please update your password using the 'Forgot Password' feature"}
          </div>
          <Formik
            initialValues={initialState}
            validationSchema={YupLoginValidation}
            onSubmit={handleLogin}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} className={``}>
                <div className={`form-row row-2`}>
                  <div className="form-group col-12">
                    <Input
                      type="email"
                      name="email"
                      label="Email"
                      placeholder={
                        locale === "id"
                          ? "Masukkan email Anda"
                          : "Insert your email"
                      }
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      value={values.email}
                    />
                  </div>
                  <div className="form-group col-12">
                    <Input
                      name="password"
                      label="Password"
                      placeholder={
                        locale === "id"
                          ? "Masukkan kata sandi Anda"
                          : "Insert your password"
                      }
                      type="password"
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      value={values.password}
                    />
                    {state.isError && (
                      <p className="error-text mt-2 mb-0 p-0">
                        {locale === "id"
                          ? "Kata sandi / email tidak valid"
                          : "Invalid password / email"}
                      </p>
                    )}
                  </div>
                </div>
                <p
                  onClick={() => onClickReset()}
                  className="forgot-text text-right py-1"
                >
                  {locale === "id"
                    ? "Tidak ingat kata sandi"
                    : "Forgot Password"}
                </p>

                <div className="btn-submit-wrapper">
                  <Button
                    // onClick={handleLogin}
                    type={"submit"}
                    loader
                    className={`${state.loading ? "loading" : ""} w-100`}
                    variant="primary"
                  >
                    {locale === "id" ? "Masuk" : "Sign In"}
                  </Button>
                </div>

                <div className="register-new-customer mt-4">
                  <p className="text-body-3 text-center">
                    {locale === "id" ? "Pelanggan baru?" : "New Customer?"}{" "}
                    <span
                      onClick={() => callback()}
                      className="hover-underline inverse pb-2"
                      style={{ cursor: "pointer" }}
                    >
                      {locale === "id" ? "Klik disini" : "Click Here"}
                    </span>
                  </p>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </ModalBs>
    // </Modal>
  )
}
