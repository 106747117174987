import React from "react"

//Utils
import { useRouter } from "next/router"

//Components
import MainDrawer from "./main-drawer"
import Subdrawer from "./sub-drawer"

const MobileDrawer = ({
  handleClose,
  isOpen,
  authState,
  setAuthState,
  handleLogout,
  modalLogin,
  modalRegister,
}) => {
  const Router = useRouter()
  //States *************************************************************************** //
  const [{ isSubNav1, isSubNav2 }, setState] = React.useState({
    isMainNav: false,
    isSubNav1: false,
    isSubNav2: false,
  })

  const [drawerData, setDrawerData] = React.useState(null)
  const [subDrawerData, setSubDrawerData] = React.useState(null)
  //States *************************************************************************** //

  //Functions ************************************************************************ //
  const handleExit = () => {
    setState({ isSubNav1: false, isSubNav2: false, isSubNav3: false })
    handleClose()
  }
  const handleSelectMenu = (item, fn) => {
    let { children, id } = item
    if (!children) {
      Router.push(`/category/${id}`)
      handleExit()
    } else {
      setDrawerData(item)
      if (fn) fn()
    }
  }

  const handleSelecSubMenu = (item, fn) => {
    let { children, slug, id } = item
    if (!children || children?.length === 0) {
      Router.push(`/category/${slug}-${id}`)
      handleExit()
    } else {
      setSubDrawerData(item)
      if (fn) fn()
    }
  }
  //Functions ************************************************************************ //

  return (
    <>
      <MainDrawer
        open={isOpen}
        close={() => handleClose()}
        next={() => setState((prev) => ({ ...prev, isSubNav1: true }))}
        handleSelectMenu={handleSelectMenu}
        authState={authState}
        setAuthState={setAuthState}
        handleLogout={handleLogout}
        modalLogin={modalLogin}
        modalRegister={modalRegister}
      />

      <Subdrawer
        open={isSubNav1}
        close={() => setState((prev) => ({ ...prev, isSubNav1: false }))}
        data={drawerData}
        handleSelectSubMenu={handleSelecSubMenu}
        next={() => setState((prev) => ({ ...prev, isSubNav2: true }))}
      />

      <Subdrawer
        open={isSubNav2}
        close={() => setState((prev) => ({ ...prev, isSubNav2: false }))}
        data={subDrawerData}
        handleSelectSubMenu={handleSelecSubMenu}
        next={() => setState((prev) => ({ ...prev, isSubNav2: true }))}
      />
    </>
  )
}

export default MobileDrawer
