import React from "react"
import PropTypes from "prop-types"

const EnhancedTextField = ({
  theme,
  variant,
  floatingLabel,
  className,
  label,
  labelClassName,
  id,
  name,
  type,
  placeholder,
  inputClassName,
  value,
  disabled,
  children,
  register,
  errors,
  icon,
  wrapperClassName,
  onChange,
  onKeyPress,
}) => {
  const fieldVariant = () => {
    if (variant === "underline") {
      return "field-underline"
    } else {
      return "field-basic"
    }
  }

  return (
    <div
      className={`field ${fieldVariant()}
        ${theme}
        ${floatingLabel ? "field-floating" : ""}
        ${!!errors ? "is-invalid" : ""}
        ${className} `}
    >
      {label && (
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
      )}

      <div
        className={`form-control-wrapper
          ${wrapperClassName} ${icon?.position}`}
      >
        <input
          {...register(name)}
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          className={`form-control ${inputClassName}`}
          value={value}
          disabled={disabled}
          autoComplete={"false"}
          onChange={onChange}
          onKeyDown={onKeyPress}
          onKeyPress={onKeyPress}
        />

        {icon && (
          <div className={`input-adornment ${icon.position}`}>{icon.el}</div>
        )}

        {floatingLabel && (
          <label className={labelClassName} htmlFor={id}>
            {label}
          </label>
        )}
        <i className="el-underline" />

        {children}
      </div>
    </div>
  )
}

EnhancedTextField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  variant: PropTypes.oneOf(["basic", "underline"]),
  theme: PropTypes.oneOf(["light", "dark"]),
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  floatingLabel: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  children: PropTypes.any,
}

EnhancedTextField.defaultProps = {
  register: () => {},
  id: "",
  type: "text",
  variant: "basic",
  theme: "light",
  label: null,
  as: "input",
  placeholder: "Enter text",
  className: "",
  labelClassName: "",
  inputClassName: "",
  wrapperClassName: "",
  hideValidationText: false,
}

export default EnhancedTextField
