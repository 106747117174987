import React from 'react';

//Components
import { Modal, Input, Button, Image } from 'components/anti'

//Utils
import * as Yup from "yup"
import { F_M_FORGOT_PASSWORD } from 'lib/function'
import { useDispatch } from 'react-redux'
import { SET_SHOW_TOAST, SET_TEXT_TOAST, SET_TYPE_TOAST } from 'lib/redux/types'
import Formik from 'formik';

import ModalAuthImg from "@public/img/sample/modal-auth.png"

// const YupRegisterValidation = Yup.object({
//    password: Yup.string()
//       .required(
//          locale === "id" ? "Kata sandi harus diisi" : "Password required"
//       )
//       .matches(
//          passwordRegex,
//          locale === "id"
//             ? "Password harus memiliki minimal 8 karakter dan minimal 1 angka"
//             : "Password must have a minimum of 8 characters and at least 1 number"
//       ),
//    password_confirmation: Yup.string()
//       .required(
//          locale === "id" ? "Kata sandi tidak cocok" : "Password does not match"
//       )
//       .oneOf(
//          [Yup.ref("password"), null],
//          locale === "id" ? "kata sandi harus cocok" : "Passwords must match"
//       ),
// });

export const ResetPassword = ({ id, hide, callback, isShowing }) => {

   const dispatch = useDispatch()
   const [state, setState] = React.useState(null);
   const [btnLoading, setBtnLoading] = React.useState(false)

   const handleSubmit = async e => {
      setBtnLoading(true)
      let { data, errors } = await F_M_FORGOT_PASSWORD({ email: state });
      if (data?.forgotPassword?.status === "EMAIL_SENT") {
         dispatch({ type: SET_SHOW_TOAST, payload: true });
         dispatch({ type: SET_TYPE_TOAST, payload: "success" });
         dispatch({ type: SET_TEXT_TOAST, payload: "Link already sent to your email" });
         setTimeout(() => {
            dispatch({ type: SET_SHOW_TOAST, payload: false });
         }, 3000)
         hide();
      } else {
         dispatch({ type: SET_SHOW_TOAST, payload: true });
         dispatch({ type: SET_TYPE_TOAST, payload: "error" });
         dispatch({ type: SET_TEXT_TOAST, payload: "Link not sent, please wait before retrying" });
         hide();
         setTimeout(() => {
            dispatch({ type: SET_SHOW_TOAST, payload: true });
         }, 3000)
      }
      setBtnLoading(true)
   };

   return (
      <Modal
         isShowing={isShowing}
         id={id}
         hide={hide}
         className="modal-md"
         contentClassName='p-0 m-0'
      >
         <div className='modal-auth'>
            <div className="img-wrapper">
               <h2 className="title-modal">NAGAREY.COM</h2>
               <Image src={ModalAuthImg} className="img-fluid" />
            </div>

            <div className="p-4">
               <p>Enter your email to receive password reset link.</p>
               <form>
                  <div className='form-group col-12 p-0'>
                     <Input
                        placeholder='Enter your password'
                        value={state}
                        name="reset-password"
                        onChange={e => setState(e.target.value)}
                     />
                  </div>
               </form>
               <div className=''>
                  <Button
                     onClick={handleSubmit}
                     className={`mt-2 w-100 ${btnLoading && "loading"}`}
                     loader
                  >
                     SENT RESET LINK
                  </Button>
               </div>
            </div>
         </div>
      </Modal>
   )
};
