// ░█▀▀▀ ░█▀▀█ ░█▀▀▀ ░█▀▀▀ 　 ▀▀█▀▀ ░█▀▀▀█ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█▀▀▀ ░█▄▄▀ ░█▀▀▀ ░█▀▀▀ 　 ─░█── ░█──░█ 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█─── ░█─░█ ░█▄▄▄ ░█▄▄▄ 　 ─░█── ░█▄▄▄█ 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be edited. However, please edit the styling on .navbar-custom.scss.

// ========================================================================================================================================
// Navbar
// ========================================================================================================================================

import React, { useState, useEffect, useRef, createRef } from "react"
import { Link } from "components/anti"
import { useRouter } from "next/router"
import NextLink from "next/link"
import HeartPNG from "assets/img/icon/ico_heart-white.svg"
import icoBuy from "assets/img/icon/ico_buy.svg"

import DesktopMenu from "./components/desktop-menu"
import MobileMenu from "./components/mobile-menu"
import SearchMenu from "./components/search-menu"
import FloatingBag from "@components/global/floating-bag/floating-bag"

import logoLight from "@public/img/common/logo_main-light.png"
import Image from "next/image"
import icFlagEnglish from "@public/img/icon/ic_flag-english.png"
import icFlagBahasa from "@public/img/icon/ic_flag-bahasa.png"
import Cookies from "js-cookie"
import { useSelector } from "react-redux"

//Modal
import { useWindowSize } from "@components/hooks/hooks"
import MobileMenuDetail from "./components/mobile-menu-detail"
import Toast from "@components/global/toast/toast"
import FloatingAddCart from "@components/global/floating-add-cart/floating-add-cart"
import { SET_SHOW_LOGIN } from "lib/redux/types"
import { initializeStore } from "lib/redux"
import { AnimatePresence, motion } from "framer-motion"

export const Navbar = ({
  handleOpen,
  authState,
  setAuthState,
  handleLogout,
  modalLogin,
  modalRegister,
}) => {
  // TEMP VARIABLE, MOVE THIS TO STORE
  const [bag, setBag] = useState(false)
  const { dispatch } = initializeStore()
  const ref = createRef(null)

  // Navbar expand config
  // Change the const below & config on variables/navbar.scss when setting the .navbar-expand-*
  // e.g. sm, md, lg, xl, xxl
  const navExpand = "lg"

  // Default theme
  // Options: "navbar-dark", "navbar-light"
  const navTheme = "navbar-dark"

  // Default position
  // Options: "fixed-top", "absolute-top", ""
  const navPosition = "fixed-top"

  // IF NO NAVBAR EXPAND USED (no responsive navbar), do the following:
  // 1. In variables/navbar.scss, set $navbar-top-height-mobile & $navbar-top-height-desktop to 0
  // 2. In variables/navbar.scss, set $navbar-main-height-mobile & $nabvar-main-height-desktop to the same height
  // 3. In variables/navbar.scss, set $navbar-expand-up to ""
  // 4. In navbar.js, edit const navbarExpand to ""

  // SCROLL
  // On scroll state
  const [navScroll, setNavScroll] = useState(false)

  const useIsomorphicLayoutEffect =
    typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect

  useIsomorphicLayoutEffect(() => {
    if (window.pageYOffset > 5) {
      setNavScroll(true)
    }
  }, [])

  useEffect(() => {
    window.onscroll = function () {
      if (window.pageYOffset > 5) {
        setNavScroll(true)
      } else {
        setNavScroll(false)
      }
    }
  }, [])

  // MENU - MOBILE
  // Open / close menu state & handler
  const { width } = useWindowSize()
  const [mobileMenu, setMobileMenu] = useState({
    initial: false,
    clicked: null,
  })
  const [mobileMenuDetail, setMobileMenuDetail] = useState(false)
  // Toggler disabled state & handler
  const [disabledMenu, setDisabledMenu] = useState(false)
  const disableMenu = () => {
    setDisabledMenu(!disabledMenu)
    setTimeout(() => {
      setDisabledMenu(false)
    }, 1500) // set this with your mobile menu animation duration
  }
  const handleMenuMobile = () => {
    disableMenu()
    if (mobileMenu.initial === false) {
      setMobileMenu({ initial: null, clicked: true })
    } else if (mobileMenu.clicked === true) {
      setMobileMenu({ clicked: !mobileMenu.clicked })
    } else if (mobileMenu.clicked === false) {
      setMobileMenu({ clicked: !mobileMenu.clicked })
    }
  }

  useEffect(() => {
    const navToggler = document.querySelector(".nav-toggler")
    const navMobileMenu = document.querySelector(".mobile-menu")
    const menuBg = document.querySelector(".menu-bg")
    const html = document.querySelector("html")
    const body = document.querySelector("body")
    // prettier-ignore
    if (mobileMenu.clicked === false) {
      // Toggle inactive
      // navToggler.classList.add("inactive")
      navMobileMenu.classList.remove("show")
      navMobileMenu.classList.add("hide")
      menuBg.classList.remove("show")
      menuBg.classList.add("hide")
      body.classList.remove("no-scroll")
      html.classList.remove("no-scroll")
    } else if (mobileMenu.clicked === true || (mobileMenu.clicked === true && mobileMenu.initial === null)) {
      // Toggle active
      // navToggler.classList.remove("inactive")
      navMobileMenu.classList.add("show")
      menuBg.classList.add("show")
      body.classList.add("no-scroll")
      html.classList.add("no-scroll")
    }
  }, [mobileMenu])

  // SEARCH
  // Open / close menu state & handler
  const [search, setSearch] = useState({
    initial: false,
    clicked: null,
  })
  const [megaNav, setMegaNav] = useState(false)
  // Toggler disabled state & handler
  const [disabledSearch, setDisabledSearch] = useState(false)
  const disableSearch = () => {
    setDisabledSearch(!disabledSearch)
    setTimeout(() => {
      setDisabledSearch(false)
    }, 1500) // set this with your mobile menu animation duration
  }
  const handleSearch = () => {
    disableSearch()
    if (search.initial === false) {
      setSearch({ initial: null, clicked: true })
    } else if (search.clicked === true) {
      setSearch({ clicked: !search.clicked })
    } else if (search.clicked === false) {
      setSearch({ clicked: !search.clicked })
    }
  }
  useEffect(() => {
    const searchMenu = document.querySelector(".search-menu")
    const navbarMain = document.querySelector(".navbar-main")
    const menuBg = document.querySelector(".menu-bg-search")
    const searchMobile = document.querySelector(".search-nav-mobile")
    // prettier-ignore
    if (search.clicked === false) {
      // Toggle inactive
      searchMenu.classList.remove("show")
      searchMenu.classList.add("hide")
      navbarMain.classList.remove("search-show")
      navbarMain.classList.add("search-hide")
      menuBg.classList.remove("show")
      menuBg.classList.add("hide")
      searchMobile.classList.remove("show")
      searchMobile.classList.add("hide")
    } else if (search.clicked === true || (search.clicked === true && search.initial === null)) {
      // Toggle active
      searchMenu.classList.add("show")
      navbarMain.classList.add("search-show")
      menuBg.classList.add("show")
      searchMobile.classList.add("show")
    }
  }, [search])

  const handleBag = () => {
    // if (asPath !== "/carts") {
    //   if (bag) {
    //     setBag(false)
    //   } else {
    //     setBag(true)
    //   }
    // }
    push("/carts")
  }

  //CUSTOM FUNCTION *********************************************************** //
  const Router = useRouter()
  let { pathname, query, asPath, locale, push } = Router
  let { carts, wishlist, add_cart, product_added } = useSelector(
    (state) => state.auth
  )
  let { categories, showToast, typeToast, textToast, showLogin } = useSelector(
    (state) => state.general
  )

  const handleChangeLang = () => {
    Router.replace(
      {
        pathname,
        query,
      },
      asPath,
      { locale: locale === "en" ? "id" : "en" }
    )
  }
  //!Authentication
  //CUSTOM FUNCTION *********************************************************** //

  return (
    <>
      {/* When giving .navbar-expand-*, don't forget to modify the variables on _navbar.scss */}

      <nav
        className={`
        navbar ${navTheme} transparent navbar-expand-${navExpand} ${navPosition} is-scrolled
        `}
        ref={ref}
      >
        <div className={`navbar-top d-none d-${navExpand}-flex`}>
          <div className="container">
            <ul className="col-left">
              <li className="nav-item">
                <div className="mr-2 flex-center" onClick={handleChangeLang}>
                  <Image
                    src={Router.locale === "en" ? icFlagBahasa : icFlagEnglish}
                    alt="ic_flag-english"
                    objectFit="contain"
                    width={20}
                  />
                  <div className="nav-link">
                    {Router.locale.toUpperCase() === "EN" ? "ID" : "EN"}
                  </div>
                </div>
              </li>
              {/* <li className="nav-item nav-divider">|</li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="https://nagarey.wpcomstaging.com/"
                  target="_blank"
                >
                  {Router.locale === "en"
                    ? "EXHIBITION / PROJECT GALLERY"
                    : "PAMERAN"}
                </Link>
              </li> */}
            </ul>
            <ul className="col-center">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  <Image
                    src={logoLight}
                    alt="logo-dark"
                    width={195}
                    height={40}
                    objectFit="contain"
                    placeholder="blur"
                  />
                </Link>
              </li>
            </ul>
            <ul className="col-right">
              <li className="nav-item">
                {authState.isLogin ? (
                  <div className="nav-link" onClick={handleLogout}>
                    {locale === "id" ? "KELUAR" : "LOGOUT"}
                  </div>
                ) : (
                  <div
                    className="nav-link"
                    onClick={() =>
                      setAuthState((prev) => ({
                        ...prev,
                        showRegisterModal: modalRegister,
                      }))
                    }
                  >
                    {locale === "id" ? "BERGABUNG" : "JOIN"}
                  </div>
                )}
              </li>
              <li className="nav-item">
                {authState.isLogin ? (
                  <Link className="nav-link" to="/account">
                    {locale === "id" ? "AKUN SAYA" : "MY ACCOUNT"}
                  </Link>
                ) : (
                  <div
                    className="nav-link"
                    onClick={() =>
                      setAuthState((prev) => ({
                        ...prev,
                        showLoginModal: modalLogin,
                      }))
                    }
                  >
                    {locale === "id" ? "MASUK" : "LOGIN"}
                  </div>
                )}
              </li>
              <li className="nav-item nav-divider">|</li>
              <li className="nav-item">
                <Link className="nav-link" to="/confirm-payment">
                  {locale === "id"
                    ? "KONFIRMASI PEMBAYARAN"
                    : "CONFIRM PAYMENT"}
                </Link>
              </li>
              <li className="nav-item nav-divider">|</li>

              {
                Cookies.get(`${process.env.ACCESS_TOKEN}`) && (
                  <li className="nav-item">
                    <div
                      className="nav-link"
                      onClick={() => {
                        window.location.href = "/wishlist"
                      }}
                    >
                      <Image
                        src={HeartPNG}
                        alt={"Icon"}
                        className="icon-heart"
                      />
                      <span className="ml-2">({wishlist})</span>
                    </div>
                  </li>
                )
                // : (
                //   <li className="nav-item">
                //     <div
                //       className="nav-link"
                //       onClick={() => {
                //         dispatch({
                //           type: SET_SHOW_LOGIN,
                //           payload: "modal-login-id",
                //         })
                //       }}
                //     >
                //       <Image src={HeartPNG} alt={"Icon"} className="icon-heart" />
                //       ({wishlist})
                //     </div>
                //   </li>
                // )
              }

              <li className="nav-item">
                <div className="nav-link" onClick={handleBag}>
                  <Image
                    src={icoBuy}
                    alt={"Icon"}
                    className="icon-shopping-bag"
                  />
                  <span className="ml-2">
                    ({carts?.items?.length > 0 ? carts?.items?.length : 0})
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <AnimatePresence>
          {((categories && categories?.length > 0) || width < 768) && (
            <motion.div
              className={`navbar-main navbar-${navExpand}`}
              initial={{ opacity: 0, y: "-100%", zIndex: -1 }}
              animate={{ opacity: 1, y: 0, zIndex: -1 }}
              transition={{
                ease: "linear",
              }}
            >
              <div className="container">
                <ul className={`col-left d-${navExpand}-none`}>
                  <li className="nav-item-brand">
                    {/* prettier-ignore */}
                    <Link className="navbar-brand absolute" to="/">
                  <img src={logoLight?.src} className="logo-light img-fluid" alt="Logo" />
                </Link>
                  </li>
                </ul>

                {/* Mobile */}
                <ul className={`col-right d-${navExpand}-none`}>
                  <li className="nav-item">
                    <button
                      className="nav-icon search-menu search-toggler"
                      disabled={disabledSearch}
                      onClick={handleSearch}
                    >
                      <i className="ais ai-search" />
                    </button>
                  </li>
                  <li className="nav-item nav-divider">|</li>
                  {Cookies.get(`${process.env.ACCESS_TOKEN}`) ? (
                    <li className="nav-item">
                      <Link className="nav-link" to="/wishlist">
                        <i className="icon-heart"></i> ({wishlist})
                      </Link>
                    </li>
                  ) : (
                    <li className="nav-item">
                      <div
                        className="nav-link"
                        onClick={() => {
                          dispatch({
                            type: SET_SHOW_LOGIN,
                            payload: "modal-login-id",
                          })
                        }}
                      >
                        <i className="icon-heart"></i> ({wishlist})
                      </div>
                    </li>
                  )}
                  <li className="nav-item nav-divider">|</li>
                  <li className="nav-item">
                    <div className="nav-link" onClick={handleBag}>
                      <i className="icon-shopping-bag"></i> (
                      {carts?.items?.length ?? 0})
                    </div>
                  </li>
                  <li className="nav-item nav-divider">|</li>
                  <li className="nav-item">
                    <button
                      className="nav-icon nav-toggler inactive absolute"
                      // disabled={disabledMenu}
                      onClick={() => handleOpen()}
                    >
                      <span className="icon-bar top-bar" />
                      <span className="icon-bar middle-bar" />{" "}
                      <span className="icon-bar bottom-bar" />
                    </button>
                  </li>
                </ul>
                <DesktopMenu
                  handleOpen={handleOpen}
                  categories={categories}
                  disabledSearch={disabledSearch}
                  handleSearch={handleSearch}
                  navExpand={navExpand}
                  handleMegaNav={setMegaNav}
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <Toast type={typeToast} text={textToast} isShow={showToast} />
        <SearchMenu
          handleSearch={handleSearch}
          navExpand={navExpand}
          search={search}
        />
        <div className="menu-bg" />
        <div className="menu-bg-search" />
        <FloatingAddCart product={product_added} active={add_cart} />
        {/* <FloatingBag setBag={setBag} bag={bag} active={bag} ref={ref} /> */}
      </nav>
    </>
  )
}
