// ░█▀▀▀ ░█▀▀█ ░█▀▀▀ ░█▀▀▀ 　 ▀▀█▀▀ ░█▀▀▀█ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█▀▀▀ ░█▄▄▀ ░█▀▀▀ ░█▀▀▀ 　 ─░█── ░█──░█ 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█─── ░█─░█ ░█▄▄▄ ░█▄▄▄ 　 ─░█── ░█▄▄▄█ 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be edited. However, please edit the styling on .footer-custom.scss.

// ========================================================================================================================================
// Footer
// ========================================================================================================================================

import React from "react"
import { Link, Button, Image } from "components/anti"

import logoLight from "@public/img/common/logo_main-light.png"
import { useRouter } from "next/router"
import NextLink from "next/link"

export const Footer = () => {
  const router = useRouter()
  const { locale } = router
  const menuLinks = [
    { title: locale === "id" ? "Tentang" : "About", link: "/about" },
    { title: locale === "id" ? "Kontak Kami" : "Contact Us", link: "/contact" },
    { title: locale === "id" ? "Toko Kami" : "Our Store", link: "/our-store" },
    { title: locale === "id" ? "Bantuan" : "Help", link: "/help" },
  ]

  const socialMedia = [
    { title: "NAGAREY Facebook", link: "https://www.facebook.com/Nagarey/" },
    {
      title: "NAGAREY Instagram",
      link: "https://www.instagram.com/accounts/login/?next=/nagarey/",
    },
    { title: "NAGAREY Pinterest", link: "https://id.pinterest.com/nagarey/" },
    {
      title: "NAGAREY Youtube",
      link: "https://www.youtube.com/channel/UCNdyL16Q8eOwY8GBdUMfcuQ",
      is_youtube: true,
    },
  ]

  const contactLinks = [
    { title: "+62 811 170 165", link: "phone:+62811170165" },
    { title: "info@antikode.com", link: "mailto:info@antikode.com" },
  ]

  return (
    <footer className="sc-dark">
      {/* Footer Top */}
      <section className="footer-top">
        <div className="container">
          <Image
            src={"/img/logo/logo_footer.png"}
            className="pt-5 pb-4 logo-brand"
          />
        </div>
      </section>

      <section className="footer-main">
        <div className="container">
          <div className="row row-4 px-4">
            <div className="col-lg-3 col-footer order-lg-2 px-0">
              <div className="footer-menu">
                <div className="navbar-nav">
                  <div className="row">
                    {menuLinks.map((link, i) => {
                      return (
                        <div key={i} className="col-12">
                          <div className="nav-item" key={i}>
                            <Link className="nav-link" to={link.link}>
                              {link.title}
                            </Link>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-footer order-lg-1 px-0">
              <div className="footer-contact">
                <div className="footer-contact__item">
                  <a href="mailto:hello@nagarey.com">
                    <i className="icon-envelope mr-2"></i>
                    hello@nagarey.com
                  </a>
                </div>
                <div className="footer-contact__item">
                  <a
                    href="https://api.whatsapp.com/send?phone=6281299069458"
                    target="__blank"
                  >
                    <i className="aib ai-whatsapp mr-2"></i>
                    +62-812-9906-9458
                  </a>
                </div>
                <div className="footer-contact__item">
                  <span>
                    {locale === "id"
                      ? "Senin - Jumat (Hari Kerja)"
                      : "Monday - Friday (Working Days)"}
                  </span>
                </div>
                <div className="footer-contact__item">
                  <span>09.00 - 17.00</span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-footer order-lg-3 px-0">
              <div className="footer-menu">
                <div className="navbar-nav">
                  <div className="row">
                    {socialMedia.map((link, i) => {
                      return (
                        <div key={i} className="col-12">
                          <div className="nav-item" key={i}>
                            <Link
                              className="nav-link social-link"
                              to={link.link}
                              target="_blank"
                            >
                              {link.title}
                            </Link>
                            {/* {link.is_youtube ? (
                              <NextLink className="nav-link" href={link.link}>
                                {link.title}
                              </NextLink>
                            ) :
                              <Link className="nav-link btn btn-link" to={link.link} target="_blank">
                                {link.title}
                              </Link>
                            } */}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Bottom */}
      <section className="footer-bottom">
        <div className="container">
          <div className="row w-100">
            <div className="col-md-6 col-left">
              ©2022 Nagarey PT Trinaga Selaras Abadi. Site by{" "}
              <a href="https://www.antikode.com/" target="_blank">
                Antikode
              </a>
              {/* . */}
            </div>

            {/* <div className="col-md-6 col-right">
              <Link to="/privacy-policy">
                {locale === "id" ? "Kebijakan Privasi" : "Privacy Policy"}
              </Link>
              <span className="divider">•</span>
              <Link to="/terms-conditions">
                {locale === "id" ? "Syarat & Ketentuan" : "Terms & Conditions"}
              </Link>
            </div> */}
          </div>
        </div>
      </section>
    </footer>
  )
}
