import React, { useState } from "react"

//Components
import { Navbar } from "components/anti"
import MobileDrawer from "./mobile-drawer"
import {
  ModalLogin,
  ModalRegister,
  ResetPassword,
} from "components/modal-component"

//Utils
import { useRouter } from "next/router"
import { useIsomorphicLayoutEffect } from "@components/hooks/hooks"
import { useDispatch, useSelector } from "react-redux"
import Cookies from "js-cookie"
import { SET_SHOW_LOGIN } from "lib/redux/types"

const NavbarWrapper = () => {
  //States *************************************************************************** //
  const [state, setState] = useState({
    isOpen: null,
  })
  const dispatch = useDispatch()
  const modalLogin = "modal-login-id"
  const modalRegister = "modal-register-id"
  const modalResetPassword = "modal-reset-password"
  let { showLogin } = useSelector((state) => state.general)

  //States *************************************************************************** //

  //Functions ************************************************************************ //
  const handleOpenMainDrawer = (fn) => {
    setState((prev) => ({ ...prev, isOpen: true }))
    if (fn) return fn()
  }

  const handleCloseMainDrawer = (fn) => {
    setState((prev) => ({ ...prev, isOpen: false }))
    if (fn) fn()
  }

  const [authState, setAuthState] = useState({
    isLogin: false,
    showLoginModal: null,
    showRegisterModal: null,
    showModalReset: null,
  })

  const handleLogout = () => {
    Cookies.remove(`${process.env.ACCESS_TOKEN}`, {
      secure: true,
      domain: process.env.BASE_DOMAIN,
    })
    Cookies.remove(`${process.env.REFRESH_TOKEN}`, {
      secure: true,
      domain: process.env.BASE_DOMAIN,
    })
    window.location.reload()
  }

  useIsomorphicLayoutEffect(() => {
    let token = Cookies.get(`${process.env.ACCESS_TOKEN}`)
    if (token) setAuthState((prev) => ({ ...prev, isLogin: true }))
  }, [])

  //Functions ************************************************************************ //

  return (
    <>
      <ResetPassword
        id={modalResetPassword}
        isShowing={authState.showModalReset}
        hide={() => setAuthState((prev) => ({ ...prev, showModalReset: null }))}
      />
      <ModalLogin
        id={modalLogin}
        show={authState.showLoginModal || showLogin}
        onClickReset={() => {
          setAuthState((prev) => ({
            ...prev,
            showModalReset: modalResetPassword,
            showLoginModal: null,
          }))
          dispatch({ type: SET_SHOW_LOGIN, payload: null })
        }}
        callback={() => {
          setAuthState((prev) => ({
            ...prev,
            showRegisterModal: modalRegister,
            showLoginModal: null,
          }))
          dispatch({ type: SET_SHOW_LOGIN, payload: null })
        }}
        hide={() => {
          setAuthState((prev) => ({ ...prev, showLoginModal: null }))
          dispatch({ type: SET_SHOW_LOGIN, payload: null })
        }}
      />
      <ModalRegister
        id={modalRegister}
        show={authState.showRegisterModal}
        callback={() => {
          setAuthState((prev) => ({
            ...prev,
            showRegisterModal: null,
            showLoginModal: modalLogin,
          }))
        }}
        hide={() =>
          setAuthState((prev) => ({ ...prev, showRegisterModal: null }))
        }
      />
      <MobileDrawer
        handleOpen={handleOpenMainDrawer}
        handleClose={handleCloseMainDrawer}
        isOpen={state.isOpen}
        authState={authState}
        setAuthState={setAuthState}
        handleLogout={handleLogout}
        modalLogin={modalLogin}
        modalRegister={modalRegister}
      />
      <Navbar
        handleOpen={handleOpenMainDrawer}
        authState={authState}
        setAuthState={setAuthState}
        handleLogout={handleLogout}
        modalLogin={modalLogin}
        modalRegister={modalRegister}
      />
    </>
  )
}

export default NavbarWrapper
