import React, { useRef } from "react"
import imgProduct from "@public/img/sample/product.jpg"
import Image from "next/image"
import { Button } from "@components/anti"
import { useClickOutside } from "components/hooks/hooks"
import { useSelector } from "react-redux"
import { currencyFormatter } from "lib/utils/utils"
import { useRouter } from "next/router"
import { initializeStore } from "lib/redux"
import { SET_ADD_CART } from "lib/redux/types"
import { useDispatch } from "react-redux"
import { F_M_UPDATE_BAG, F_M_DELETE_CART, F_Q_CARTS } from "lib/function"
import { SET_CARTS } from "lib/redux/types"
import icoCheckmart from "assets/img/icon/ico_checkmark_white-small.svg"

export const BagItem = ({
  image,
  price,
  sell_price,
  product_id,
  quantity,
  sku_id,
  title,
  firstcategory,
  ...props
}) => {
  //States
  const [state, setState] = React.useState({
    qty: quantity,
    btnLoading: false,
  })

  //Functions
  const handleClick = async (type) => {
    if (type === "inc") {
      if (state.qty < stock) {
        setState((prev) => ({ ...prev, qty: state.qty + 1, btnDis: true }))
        let { data, errors } = await F_M_UPDATE_BAG({
          id: id,
          quantity: Number(state.qty + 1),
        })
        if (data) {
          let { updateCart } = data
          if (updateCart) {
            dispatch({ type: SET_CARTS, payload: updateCart })
            setState((prev) => ({ ...prev, btnDis: false }))
          } else {
          }
        } else {
          //!NEED ERROR HANDLING
          setState((prev) => ({ ...prev, btnDis: false }))
        }
      }
    } else if (type === "dec") {
      if (state.qty > 1) {
        setState((prev) => ({ ...prev, qty: state.qty - 1 }))
        let { data, errors } = await F_M_UPDATE_BAG({
          id: id,
          quantity: Number(state.qty - 1),
        })
        if (data) {
          let { updateCart } = data
          if (updateCart) {
            dispatch({ type: SET_CARTS, payload: updateCart })
            setState((prev) => ({ ...prev, btnDis: false }))
          } else {
          }
        } else {
          //!NEED ERROR HANDLING
          setState((prev) => ({ ...prev, btnDis: false }))
        }
      }
    }
  }

  return (
    <>
      <div className="bag-item d-flex">
        <Image
          src={image}
          alt="img-card-product"
          objectFit="cover"
          width={110}
          height={140}
          // placeholder="blur"
        />

        <div className="bag-item__content ml-3">
          <div className="bag-item__desc">
            <h6 className="text-title-3">{title}</h6>
            <div className="mt-2">
              {price && sell_price < price && (
                <div className="subtotal p-0 m-0">
                  {currencyFormatter("ID", price)}
                </div>
              )}
            </div>
            <div className="row align-items-end">
              <div className="col-md-9">
                {/* <div className="text-price"> */}
                <span className="text-price">
                  {currencyFormatter("ID", sell_price)} x {quantity}PC(S)
                </span>
                {/* </div> */}
              </div>
              <div className="col-md-3">
                {/* <div className="text-price"> */}
                <span className="text-price">
                  {currencyFormatter("ID", sell_price * quantity)}
                </span>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const FloatingAddCart = ({ product, active }) => {
  const ref = useRef()
  const router = useRouter()
  const { locale } = router
  const { dispatch } = initializeStore()

  const { carts } = useSelector((state) => state.auth)

  const [state, setState] = React.useState({
    btnLoading: false,
  })

  useClickOutside(ref, () => {
    if (active) {
      dispatch({ type: SET_ADD_CART, payload: false })
    }
  })

  const handleClose = () => {
    dispatch({ type: SET_ADD_CART, payload: false })
  }

  const handleNavigate = () => {
    setState((prev) => ({ ...prev, btnLoading: true }))
    // window.location.href = "/carts"
    router.push("/carts")
  }

  React.useEffect(() => {
    if (active) {
    }
  }, [active])

  return (
    <div className="container justify-content-end">
      <div
        ref={ref}
        className={`floating-add-cart-wrapper ${active ? "active" : ""}`}
        // className={`floating-add-cart-wrapper active`}
      >
        <div className="floating-add-cart">
          {/* <i className="air ai-check-circle" style={{ fontSize: "2rem", color: "red", background: "blue" }}></i> */}
          <div className="wrapper-header">
            <div className="title-wrapper">
              <div>
                {/* <span className="ail ai-check-circle" /> */}
                <img
                  src={icoCheckmart?.src}
                  alt={"icon"}
                  className="custom-checkmark"
                />
              </div>
              <h4 className="text">
                {locale === "id"
                  ? `Anda telah menambahkan ${
                      product?.quantity ?? 0
                    } produk ke keranjang belanja Anda`
                  : `You've added ${
                      product?.quantity ?? 0
                    } product to your shopping cart`}
              </h4>
            </div>
            <div className="close-wrapper">
              <i className="ail ai-times" onClick={() => handleClose()}></i>
            </div>
          </div>
          {/* <div className="floating-bag-list w-100">
            {product && <BagItem {...product} />}
          </div> */}
          <div className="btn-wrapper">
            <Button
              variant="primary"
              loader
              className={`btn-fluid ${state.btnLoading ? "loading" : ""}`}
              onClick={() => handleNavigate()}
            >
              {locale === "id" ? "Lihat Keranjang Saya" : "View My Cart"}
            </Button>
            <Button
              variant="white"
              className="btn-fluid"
              onClick={() => handleClose()}
            >
              {locale === "id" ? "Lanjutkan Belanja" : "Continue Shopping"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FloatingAddCart
