import { initializeStore } from "lib/redux"
import { SET_SHOW_TOAST, SET_TYPE_TOAST } from "lib/redux/types"
import checkMark from "assets/img/icon/ico_checkmark_white-small.svg"
import warning from "assets/img/icon/ico_warning-small.svg"
import { Image } from "@components/anti"

const Toast = ({ type, text, isShow }) => {
  const { dispatch } = initializeStore()

  const handleClose = () => {
    dispatch({ type: SET_SHOW_TOAST, payload: false })
    dispatch({ type: SET_TYPE_TOAST, payload: null })
  }

  return (
    <div className="container justify-content-end">
      <div
        className={`toast-wrapper toast-${
          type === "error" ? "error" : "success"
        } ${isShow ? "active" : ""}`}
        // className={`toast-wrapper toast-success active`}
      >
        <div className="icon-wrapper">
          {type === "error" && (
            <Image src={warning} alt="warning" className="img-fluid warning" />
          )}
          {type === "success" && (
            <Image
              src={checkMark}
              alt="checkmark"
              className="img-fluid checkmark"
            />
          )}
        </div>
        <div className="text-wrapper">{text}</div>
        <div className="btn-close">
          <span
            onClick={() => handleClose()}
            className="ail ai-times"
            role="button"
            tabIndex={0}
          />
        </div>
      </div>
    </div>
  )
}

export default Toast
