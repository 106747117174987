// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be used flexibly. But if you REALLY MUST update edit the component,
// please add `-custom` on the change log file (e.g. `1.0.0-custom.md`) and log the changes

// ==========================================================================
// Forms - Radio
// ==========================================================================

import React from "react"
import { Field } from "formik"
import PropTypes from "prop-types"

export const RadioCustom = ({
  formik,
  id,
  name,
  value,
  label,
  className,
  labelClassName,
  inputClassName,
  img,
  handleChangeFilter,
  isChecked,
  onChange,
  onClick,
  disabled,
  subText,
  variant,
  children,
  classNameWrapper,
  errorText,
}) => {
  return (
    <>
      <div
        className={`form-check form-check-custom ${variant} ${classNameWrapper}`}
        onClick={() => handleChangeFilter(value)}
      >
        <div className={`${className} position-relative`}>
          <input
            type="radio"
            id={id}
            name={name}
            className={`form-check-input ${inputClassName}`}
            checked={!isChecked ? false : true}
            value={null}
            onChange={onChange}
            disabled={disabled}
          />
          <div className="el-after" />
          <label className={`form-check-label ${labelClassName}`} htmlFor={id}>
            <div className="wrapper-label-custom-radio">
              {img && <img src={img} className="img-fluid" alt={name} />}
              <div className="form-check-label-custom">
                {label}
                {subText ? (
                  <div className="form-label-subtext">{subText}</div>
                ) : null}
              </div>
              {errorText && (
                <div className="form-label-subtext text-danger">
                  {errorText}
                </div>
              )}
            </div>
          </label>
        </div>
        {children}
      </div>
      {/* )} */}
    </>
  )
}

RadioCustom.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.any,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  defaultValue: PropTypes.array,
}

RadioCustom.defaultProps = {
  id: "",
  className: "",
  labelClassName: "",
  inputClassName: "",
  defaultValue: [25, 30],
}
