import React, { forwardRef, useRef } from "react"
import imgProduct from "@public/img/sample/product.jpg"
import Image from "next/image"
import icoBuy from "assets/img/icon/ico_buy.png"
import { Button } from "@components/anti"
import { useClickOutside } from "components/hooks/hooks"
import { useSelector } from "react-redux"
import { currencyFormatter } from "lib/utils/utils"
import { useRouter } from "next/router"
import { useDispatch } from "react-redux"
import { F_M_UPDATE_BAG, F_M_DELETE_CART, F_Q_CARTS } from "lib/function"
import { SET_CARTS } from "lib/redux/types"
import Cookies from "js-cookie"

export const BagItem = ({
  image,
  price,
  sell_price,
  product_id,
  quantity,
  sku_id,
  title,
  stock,
  id,
}) => {
  //States
  const [state, setState] = React.useState({
    qty: quantity,
    incDis: null,
    decDis: null,
    btnDis: null,
  })

  const dispatch = useDispatch()
  const Router = useRouter()

  //Functions **************************************************** //
  const handleClick = async (type) => {
    if (type === "inc") {
      if (state.qty < stock) {
        setState((prev) => ({ ...prev, qty: state.qty + 1, btnDis: true }))
        let { data, errors } = await F_M_UPDATE_BAG({
          id: id,
          quantity: Number(state.qty + 1),
        })
        if (data) {
          let { updateCart } = data
          if (updateCart) {
            dispatch({ type: SET_CARTS, payload: updateCart })
            setState((prev) => ({ ...prev, btnDis: false }))
          } else {
          }
        } else {
          //!NEED ERROR HANDLING
          setState((prev) => ({ ...prev, btnDis: false }))
        }
      }
    } else if (type === "dec") {
      if (state.qty > 1) {
        setState((prev) => ({ ...prev, qty: state.qty - 1 }))
        let { data, errors } = await F_M_UPDATE_BAG({
          id: id,
          quantity: Number(state.qty - 1),
        })
        if (data) {
          let { updateCart } = data
          if (updateCart) {
            dispatch({ type: SET_CARTS, payload: updateCart })
            setState((prev) => ({ ...prev, btnDis: false }))
          } else {
          }
        } else {
          //!NEED ERROR HANDLING
          setState((prev) => ({ ...prev, btnDis: false }))
        }
      }
    }
  }

  const handleDeleteBag = async () => {
    let { data, errors } = await F_M_DELETE_CART({ id, lang: Router.locale })
    if (data) {
      let { status } = data?.deleteCart
      if (status === "success") {
        let token =
          Cookies.get(`${process.env.ACCESS_TOKEN}`) ||
          Cookies.get(`${process.env.GUEST_TOKEN}`)
        let cartData = await F_Q_CARTS({ lang: Router.locale }, token)
        if (cartData && cartData.data) {
          let { carts } = cartData.data
          dispatch({ type: SET_CARTS, payload: carts })
        }
      }
    }
  }
  //Functions **************************************************** //

  return (
    <>
      <div className="bag-item flex-align-center">
        <Image
          src={image}
          alt="img-card-product"
          objectFit="cover"
          width={160}
          height={200}
          // placeholder="blur"
        />
        <div className="bag-item__content ml-3">
          <div className="bag-item__desc mb-4">
            <h6 className="h5">{title}</h6>
            <div className="mt-2">
              {price && sell_price < price && (
                <div className="subtotal p-0 m-0">Rp{price}</div>
              )}
            </div>
            <div className="price">
              <span>{currencyFormatter("ID", sell_price)}</span>
            </div>
          </div>

          <div className="bag-item-qty">
            <small className="caption d-block mb-1">QTY</small>

            <div className="bag-item-qty__wrapper">
              <div className="bag-item-qty__adjust">
                {/* <i
                  className="icon-qty-min"
                  onClick={() => handleClick("dec")}
                ></i> */}
                <Button
                  variant="outline-secondary"
                  onClick={() => handleClick("dec")}
                >
                  -{/* <h5 className="mb-0">-</h5> */}
                </Button>
                <input
                  type="number"
                  className="form-control"
                  value={state.qty}
                />
                {/* <i
                  className="icon-qty-plus"
                  onClick={() => handleClick("inc")}
                ></i> */}
                <Button
                  variant="outline-secondary"
                  onClick={() => handleClick("inc")}
                >
                  +{/* <h5 className="mb-0">+</h5> */}
                </Button>
              </div>
              <Button
                variant="outline-danger"
                onClick={(e) => handleDeleteBag()}
              >
                <i className="air ai-times"></i>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const FloatingBag = forwardRef(({ bag, setBag, active }, ref) => {
  // const ref = useRef()
  const router = useRouter()
  const { locale } = router
  const [btnLoading, setBtnLoading] = React.useState(false)

  const { carts } = useSelector((state) => state.auth)

  useClickOutside(ref, () => {
    if (bag) {
      setBag(false)
    }
  })

  const handleNavigate = () => {
    setBtnLoading(true)
    // window.location.href = "/carts"
    router.push("/carts")
  }

  return (
    <>
      <div
        // ref={ref}
        className={`floating-bag-wrapper ${active ? "active" : ""}`}
      >
        <div className="floating-bag">
          <div className="flex-center-between mb-2">
            <h4>{locale === "id" ? "Keranjang Saya" : "My Cart"}</h4>
            <i
              className="air ai-times text-right"
              onClick={() => setBag(false)}
            ></i>
          </div>
          {carts?.items?.length > 0 ? (
            <>
              <div className="floating-bag-list">
                {carts?.items?.length > 0 ? (
                  <>
                    {carts.items.map((item, i) => (
                      <BagItem {...item} key={i} />
                    ))}
                  </>
                ) : null}
              </div>
              <div className="grand-total flex-center-between">
                <span>Total</span>
                <h2>{currencyFormatter("ID", carts?.grand_total ?? 0)}</h2>
              </div>
              <Button
                loader
                variant="primary"
                className={`btn-fluid ${btnLoading ? "loading" : ""}`}
                onClick={() => handleNavigate()}
              >
                {locale === "id" ? "LIHAT KERANJANG SAYA" : "VIEW MY CART"}
              </Button>
            </>
          ) : (
            <div className="floating-bag__empty">
              <Image
                src={icoBuy}
                alt="img-card-product"
                objectFit="cover"
                width={72}
                height={72}
                placeholder="blur"
              />
              <p className="m-0 text-title-2">
                {locale === "id"
                  ? "Keranjang kamu kosong"
                  : "Your Cart is Empty"}
              </p>
              <p className="m-0 text-body-3">
                {locale === "id"
                  ? "Jelajahi produk kami untuk mulai menambahkan item ke keranjang."
                  : "Explore our products to start adding item(s) to cart."}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  )
})

export default FloatingBag
