import React, { createRef, useRef, useState } from "react"
import { Link } from "components/anti/link/link"
import { Button } from "components/anti/buttons/buttons"
import EnhancedTextField from "components/anti/forms/react-hook-form/text-field"
import { useClickOutside } from "components/hooks/hooks"
import { useRouter } from "next/router"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import { AnimatePresence, motion } from "framer-motion"

const DesktopNavItem = ({
  name,
  data,
  slug,
  handleMegaNav,
  banner_type,
  handleOpen,
}) => {
  const ref = useRef()
  const router = useRouter()
  const { asPath } = router
  const categoryId = asPath.split("-").slice(-1)[0]
  const [collapse, setCollapse] = useState()
  const [active, setActive] = useState(false)

  const handleMegamenu = () => {
    handleMegaNav(!collapse)
    if (collapse) {
      setCollapse(false)
      return
    }
    setCollapse(!collapse)
  }

  useClickOutside(ref, () => {
    if (collapse) {
      handleMegaNav(false)
      setCollapse(false)
    }
  })

  const customColom = (length) => {
    let colCustom = Math.ceil(length / 3)
    return `custom-col-${colCustom}`
  }

  useEffect(async () => {
    let temp = []
    if (data) {
      await data.forEach((item) => {
        temp.push(item.id)
        item.children.forEach((item2) => {
          temp.push(item2.id)
        })
      })

      if (temp.includes(categoryId)) {
        setActive(true)
      } else {
        setActive(false)
      }
    }

    return () => {
      temp = []
    }
  }, [data])

  return (
    <div ref={ref}>
      <Link
        className={`nav-link ${banner_type === "dark" && "text-light"} ${
          collapse ? `nav-link-collapse` : ``
        } ${active ? "nav-link-collapse" : ""}`}
        onClick={handleMegamenu}
      >
        {name}
      </Link>

      <div
        className={`desktop-megamenu ${
          collapse == null ? `initial` : !!collapse ? `active` : `hide`
        }`}
      >
        <div className="container">
          <div className={"megamenu-nav-wrapper"}>
            {data?.map((item) => {
              return (
                <div className={`megamenu-nav`} key={item.id}>
                  <h6
                    onClick={() => {
                      router.push(`/category/${item.slug}-${item.id}`)
                      handleMegaNav(false)
                      setCollapse(false)
                    }}
                    className={`title ${
                      item.children.length > 0 ? "" : "no-child"
                    } ${categoryId === item.id ? "active" : ""}`}
                  >
                    {item.title}
                  </h6>
                  {item.children.length > 0 && (
                    <ul className={`nav-item-list`}>
                      {item.children.map((item2) => {
                        return (
                          <li
                            key={item2.id}
                            className={`nav-item ${
                              categoryId === item2.id ? "active" : ""
                            }`}
                          >
                            <Button
                              variant="link"
                              className="hover-underline p-0"
                              link={`/category/${item2.slug}-${item2.id}`}
                              onClick={() => {
                                handleMegaNav(false)
                                setCollapse(false)
                              }}
                            >
                              {item2.title}
                            </Button>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const DesktopMenu = ({
  navExpand,
  handleSearch,
  disabledSearch,
  categories,
  handleMegaNav,
  handleOpen,
}) => {
  const resultUrl = "/result"
  const ref = createRef()
  //Utils
  const Router = useRouter()
  const { locale } = Router

  //States
  const [state, setState] = React.useState({
    searchType: Router.query?.type ?? "",
  })

  const { banner_type } = useSelector((state) => state.general)

  //Functions
  const handleEnterSearch = (e) => {
    if (e.key === "Enter") {
      Router.push(`${resultUrl}?type=${state.searchType}`)
      // Router.prefetch(`${resultUrl}?type=${state.searchType}`)
    }
  }

  return (
    <div className={`desktop-menu d-none d-${navExpand}-flex`} ref={ref}>
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link
            className={`nav-link ${banner_type === "dark" && "text-light"}`}
            activeClassName="active"
            to={`/category/new`}
          >
            {locale === "id" ? "Terbaru" : "New"}
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link text-danger ${
              banner_type === "dark" && "text-light"
            }`}
            activeClassName="active"
            to={`/category/sale`}
          >
            Sale
          </Link>
        </li>
        {categories && categories?.length > 0
          ? categories?.map((item) => (
              <li className="nav-item" key={item.id}>
                {item.children.length > 0 ? (
                  <DesktopNavItem
                    handleOpen={handleOpen}
                    banner_type={banner_type}
                    name={item.title}
                    slug={item.slug}
                    data={item.children}
                    handleMegaNav={handleMegaNav}
                  />
                ) : (
                  <Link
                    className={`nav-link ${
                      banner_type === "dark" && "text-light"
                    }`}
                    activeClassName="active"
                    to={`/category/${item.slug}-${item.id}`}
                  >
                    {item.title}
                  </Link>
                )}
              </li>
            ))
          : null}
      </ul>
      <div className="col-right">
        <li className="nav-item nav-search">
          <EnhancedTextField
            onChange={(e) =>
              setState((prev) => ({ ...prev, searchType: e.target.value }))
            }
            id="search"
            theme={"light"}
            name="search"
            variant="underline"
            placeholder={
              Router.locale === "id" ? "Cari disini..." : "Search here..."
            }
            value={state.searchType}
            icon={{
              position: "adornment-end",
              el: <i className="air ai-search"></i>,
            }}
            onKeyPress={handleEnterSearch}
            inputClassName={`form-search`}
          />
        </li>
      </div>
    </div>
  )
}

export default DesktopMenu
