import { staticApollo } from "lib/apollo"
import {
  M_LOGIN,
  M_LOGOUT,
  M_REGISTER,
  M_CREATE_UPDATE_ADDRESS,
  M_ADD_CART,
  M_ADD_WISHLIST,
  M_UPDATE_PASSWORD,
  M_CREATEA_ORDER,
  M_UPDATE_QTY_CART,
  M_UPDATE_USER,
  M_DELETE_ADDRESS,
  M_DELETE_CART_ITEM,
  M_CONFIRM_PAYMENT,
  M_CONTACT_FORM,
  M_FORGOT_PASSWORD,
  M_FORGOTTEN_PASSWORD,
  M_REMIND_ME,
  M_REPAYMENT,
  M_ADD_NOTIFY,
  M_CREATE_ORDER_WITH_BILLING,
} from "lib/graphql"
import Cookies from "js-cookie"

/**

░█─── ░█▀▀▀█ ░█▀▀█ ▀█▀ ░█▄─░█
░█─── ░█──░█ ░█─▄▄ ░█─ ░█░█░█
░█▄▄█ ░█▄▄▄█ ░█▄▄█ ▄█▄ ░█──▀█
 * @param {*} values
   example values: { username: "John Doe", password: "1234", phone_number: "" }
 * @returns { data, errors }
 */
export const F_M_LOGIN = async (values) => {
  let token = Cookies.get(`${process.env.GUEST_TOKEN}`)
  if (token) {
    const client = staticApollo(token)
    let { data, errors } = await client.mutate({
      mutation: M_LOGIN,
      variables: { ...values },
    })
    if (data) return { data, errors: null }
    else return { data: null, errors }
  } else {
    return { data: null, errors: { message: "NO TOKEN" } }
  }
}

/**
▒█▀▀█ ▒█▀▀▀ ▒█▀▀█ ▀█▀ ▒█▀▀▀█ ▀▀█▀▀ ▒█▀▀▀ ▒█▀▀█
▒█▄▄▀ ▒█▀▀▀ ▒█░▄▄ ▒█░ ░▀▀▀▄▄ ░▒█░░ ▒█▀▀▀ ▒█▄▄▀
▒█░▒█ ▒█▄▄▄ ▒█▄▄█ ▄█▄ ▒█▄▄▄█ ░▒█░░ ▒█▄▄▄ ▒█░▒█
 * @param {*} values
   sample values: {
      name: "John Doe" String!
      email: "johndoe@mail.com" String!
      gender: "male" String!
      password: "1234" String!
      password_confirmation: "1234" String!
      is_subscribe: Int!
   }
 * @returns { data, errors }
 */
export const F_M_REGISTER = async (values) => {
  let token = Cookies.get(`${process.env.GUEST_TOKEN}`)
  const client = staticApollo(token)
  if (token) {
    try {
      let { data, errors } = await client.mutate({
        mutation: M_REGISTER,
        variables: { ...values },
        errorPolicy: "all",
      })
      if (data) return { data, errors: null }
      else return { data: null, errors }
    } catch (error) {
      return { data: null, errors: error }
    }
  } else {
    return { data: null, errors: { message: "NO TOKEN" } }
  }
}

/**
▒█░░░ ▒█▀▀▀█ ▒█▀▀█ ▒█▀▀▀█ ▒█░▒█ ▀▀█▀▀
▒█░░░ ▒█░░▒█ ▒█░▄▄ ▒█░░▒█ ▒█░▒█ ░▒█░░
▒█▄▄█ ▒█▄▄▄█ ▒█▄▄█ ▒█▄▄▄█ ░▀▄▄▀ ░▒█░░
 * return { data, errors }
 * @returns { data, errors }
 */
export const F_M_LOGOUT = async () => {
  let token = Cookies.get(`${process.env.ACCESS_TOKEN}`)
  if (token) {
    const client = staticApollo(token)
    let { data, errors } = await client.mutate({
      mutation: M_LOGOUT,
      errorPolicy: "all",
    })
    if (data) return { data: null, errors: null }
    else return { data: null, errors }
  } else {
    return { data: null, errors: { message: "NO TOKEN" } }
  }
}

/**
░█▀▀█ ▒█▀▀▄ ▒█▀▀▄ ▒█▀▀█ ▒█▀▀▀ ▒█▀▀▀█ ▒█▀▀▀█
▒█▄▄█ ▒█░▒█ ▒█░▒█ ▒█▄▄▀ ▒█▀▀▀ ░▀▀▀▄▄ ░▀▀▀▄▄
▒█░▒█ ▒█▄▄▀ ▒█▄▄▀ ▒█░▒█ ▒█▄▄▄ ▒█▄▄▄█ ▒█▄▄▄█
 *
 * @param {*} values
 * @returns
 */
export const F_M_CREATE_UPDATE_ADDRESS = async (values) => {
  let token = Cookies.get(`${process.env.ACCESS_TOKEN}`)
  if (token) {
    try {
      const client = staticApollo(token)
      let { data, errors } = await client.mutate({
        mutation: M_CREATE_UPDATE_ADDRESS,
        variables: { ...values },
        errorPolicy: "all",
      })
      if (data) return { data, errors: null }
      else return { data: null, errors }
    } catch (error) {
      return { data: null, errors: error }
    }
  } else {
    return { data: null, errors: { message: "NO TOKEN" } }
  }
}

/**
▒█▀▀█ ▒█▀▀█ ▒█▀▀▀ ░█▀▀█ ▀▀█▀▀ ▒█▀▀▀ 　 ▒█▀▀▀█ ▒█▀▀█ ▒█▀▀▄ ▒█▀▀▀ ▒█▀▀█
▒█░░░ ▒█▄▄▀ ▒█▀▀▀ ▒█▄▄█ ░▒█░░ ▒█▀▀▀ 　 ▒█░░▒█ ▒█▄▄▀ ▒█░▒█ ▒█▀▀▀ ▒█▄▄▀
▒█▄▄█ ▒█░▒█ ▒█▄▄▄ ▒█░▒█ ░▒█░░ ▒█▄▄▄ 　 ▒█▄▄▄█ ▒█░▒█ ▒█▄▄▀ ▒█▄▄▄ ▒█░▒█
 * @param {*} values
 * @returns
 */
export const F_M_CREATE_ORDER = async (values) => {
  let authToken = Cookies.get(`${process.env.ACCESS_TOKEN}`)
  let guestToken = Cookies.get(`${process.env.GUEST_TOKEN}`)
  let client

  if (authToken) client = staticApollo(authToken)
  else client = staticApollo(guestToken)

  try {
    let { data, errors } = await client.mutate({
      mutation: M_CREATEA_ORDER,
      variables: { ...values },
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

/**
░█▀▀█ ▒█▀▀▄ ▒█▀▀▄ 　 ▀▀█▀▀ ▒█▀▀▀█ 　 ▒█▀▀█ ░█▀▀█ ▒█▀▀█
▒█▄▄█ ▒█░▒█ ▒█░▒█ 　 ░▒█░░ ▒█░░▒█ 　 ▒█▀▀▄ ▒█▄▄█ ▒█░▄▄
▒█░▒█ ▒█▄▄▀ ▒█▄▄▀ 　 ░▒█░░ ▒█▄▄▄█ 　 ▒█▄▄█ ▒█░▒█ ▒█▄▄█
 * @param {*} values
 * @returns
 */
export const F_M_ADD_TO_BAG = async (values, token) => {
  const client = staticApollo(token)
  try {
    let { data, errors } = await client.mutate({
      mutation: M_ADD_CART,
      variables: { ...values },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    if (data) return { data, errors: null }
    else return { data: null, errors }
  } catch (error) {
    return { data: null, errors: error }
  }
}

/**
▒█░▒█ ▒█▀▀█ ▒█▀▀▄ ░█▀▀█ ▀▀█▀▀ ▒█▀▀▀ 　 ▒█▀▀█ ░█▀▀█ ▒█▀▀█
▒█░▒█ ▒█▄▄█ ▒█░▒█ ▒█▄▄█ ░▒█░░ ▒█▀▀▀ 　 ▒█▀▀▄ ▒█▄▄█ ▒█░▄▄
░▀▄▄▀ ▒█░░░ ▒█▄▄▀ ▒█░▒█ ░▒█░░ ▒█▄▄▄ 　 ▒█▄▄█ ▒█░▒█ ▒█▄▄█
 * @param {*} values
   Sample values: { id: String!, quantity: Int! }
 * @returns
 */
export const F_M_UPDATE_BAG = async (values) => {
  let authToken = Cookies.get(`${process.env.ACCESS_TOKEN}`)
  let guestToken = Cookies.get(`${process.env.GUEST_TOKEN}`)
  let client

  if (authToken) client = staticApollo(authToken)
  else client = staticApollo(guestToken)

  try {
    let { data, errors } = await client.mutate({
      mutation: M_UPDATE_QTY_CART,
      variables: { ...values },
      errorPolicy: "all",
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

/**
─█▀▀█ ░█▀▀▄ ░█▀▀▄ 　 ░█──░█ ▀█▀ ░█▀▀▀█ ░█─░█ ░█─── ▀█▀ ░█▀▀▀█ ▀▀█▀▀
░█▄▄█ ░█─░█ ░█─░█ 　 ░█░█░█ ░█─ ─▀▀▀▄▄ ░█▀▀█ ░█─── ░█─ ─▀▀▀▄▄ ─░█──
░█─░█ ░█▄▄▀ ░█▄▄▀ 　 ░█▄▀▄█ ▄█▄ ░█▄▄▄█ ░█─░█ ░█▄▄█ ▄█▄ ░█▄▄▄█ ─░█──
 * @param {*} values
   sample values: { product_id: ID! }
 * @returns
 */
export const F_M_ADD_WISHLIST = async (values) => {
  let token = Cookies.get(`${process.env.ACCESS_TOKEN}`)
  if (token) {
    try {
      const client = staticApollo(token)
      let { data, errors } = await client.mutate({
        mutation: M_ADD_WISHLIST,
        variables: { ...values },
        errorPolicy: "all",
      })
      if (data) return { data, errors: null }
      else return { data: null, errors }
    } catch (error) {
      return { data: null, errors: error }
    }
  } else {
    return { data: null, errors: { message: "NO TOKEN" } }
  }
}

/**
 *
░█─░█ ░█▀▀█ ░█▀▀▄ ─█▀▀█ ▀▀█▀▀ ░█▀▀▀ 　 ░█▀▀█ ─█▀▀█ ░█▀▀▀█ ░█▀▀▀█ ░█──░█ ░█▀▀▀█ ░█▀▀█ ░█▀▀▄
░█─░█ ░█▄▄█ ░█─░█ ░█▄▄█ ─░█── ░█▀▀▀ 　 ░█▄▄█ ░█▄▄█ ─▀▀▀▄▄ ─▀▀▀▄▄ ░█░█░█ ░█──░█ ░█▄▄▀ ░█─░█
─▀▄▄▀ ░█─── ░█▄▄▀ ░█─░█ ─░█── ░█▄▄▄ 　 ░█─── ░█─░█ ░█▄▄▄█ ░█▄▄▄█ ░█▄▀▄█ ░█▄▄▄█ ░█─░█ ░█▄▄▀
 * @param {*} values
   Sample Values: { old_password: "1234", password: "12345", password_confirmation: "12345" }
 * @returns
 */
export const F_M_UPDATE_PASSWORD = async (values) => {
  let token = Cookies.get(`${process.env.ACCESS_TOKEN}`)
  if (token) {
    try {
      const client = staticApollo(token)
      let { data, errors } = await client.mutate({
        mutation: M_UPDATE_PASSWORD,
        variables: { ...values },
        errorPolicy: "all",
      })
      if (data) return { data, errors: null }
      else return { data: null, errors: errors }
    } catch (error) {
      return { data: null, errors: error }
    }
  } else {
    return { data: null, errors: { message: "NO TOKEN" } }
  }
}

/**
 *
░█─░█ ░█▀▀█ ░█▀▀▄ ─█▀▀█ ▀▀█▀▀ ░█▀▀▀ 　 ░█─░█ ░█▀▀▀█ ░█▀▀▀ ░█▀▀█
░█─░█ ░█▄▄█ ░█─░█ ░█▄▄█ ─░█── ░█▀▀▀ 　 ░█─░█ ─▀▀▀▄▄ ░█▀▀▀ ░█▄▄▀
─▀▄▄▀ ░█─── ░█▄▄▀ ░█─░█ ─░█── ░█▄▄▄ 　 ─▀▄▄▀ ░█▄▄▄█ ░█▄▄▄ ░█─░█
 * @param {*} values
 * @returns
 */
export const F_M_UPDATE_USER = async (values) => {
  let token = Cookies.get(`${process.env.ACCESS_TOKEN}`)
  if (!token) return { errors: { message: "NO TOKEN" } }

  const client = staticApollo(token)
  try {
    let { data, errors } = await client.mutate({
      mutation: M_UPDATE_USER,
      variables: { ...values },
      errorPolicy: "all",
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

/**
 *
▒█▀▀▄ ▒█▀▀▀ ▒█░░░ ▒█▀▀▀ ▀▀█▀▀ ▒█▀▀▀ 　 ░█▀▀█ ▒█▀▀▄ ▒█▀▀▄ ▒█▀▀█ ▒█▀▀▀ ▒█▀▀▀█ ▒█▀▀▀█
▒█░▒█ ▒█▀▀▀ ▒█░░░ ▒█▀▀▀ ░▒█░░ ▒█▀▀▀ 　 ▒█▄▄█ ▒█░▒█ ▒█░▒█ ▒█▄▄▀ ▒█▀▀▀ ░▀▀▀▄▄ ░▀▀▀▄▄
▒█▄▄▀ ▒█▄▄▄ ▒█▄▄█ ▒█▄▄▄ ░▒█░░ ▒█▄▄▄ 　 ▒█░▒█ ▒█▄▄▀ ▒█▄▄▀ ▒█░▒█ ▒█▄▄▄ ▒█▄▄▄█ ▒█▄▄▄█
 * @param {*} values
   sample values: { id: "2" } ID!
 * @returns
 */
export const F_M_DELETE_ADDRESS = async (values) => {
  let token = Cookies.get(`${process.env.ACCESS_TOKEN}`)
  if (!token) return { errors: { message: "NO TOKEN" } }

  const client = staticApollo(token)

  try {
    let { data, errors } = await client.mutate({
      mutation: M_DELETE_ADDRESS,
      variables: { ...values },
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

/**
 *
░█▀▀▄ ░█▀▀▀ ░█─── ░█▀▀▀ ▀▀█▀▀ ░█▀▀▀ 　 ░█▀▀█ ─█▀▀█ ░█▀▀█ ▀▀█▀▀
░█─░█ ░█▀▀▀ ░█─── ░█▀▀▀ ─░█── ░█▀▀▀ 　 ░█─── ░█▄▄█ ░█▄▄▀ ─░█──
░█▄▄▀ ░█▄▄▄ ░█▄▄█ ░█▄▄▄ ─░█── ░█▄▄▄ 　 ░█▄▄█ ░█─░█ ░█─░█ ─░█──
 * @param {*} values
   sample values: {
      id: "2" ID!,
    }
 * @returns
 */
export const F_M_DELETE_CART = async (values) => {
  let accessToken = Cookies.get(`${process.env.ACCESS_TOKEN}`)
  let guestToken = Cookies.get(`${process.env.GUEST_TOKEN}`)
  let client
  if (accessToken) client = staticApollo(accessToken)
  else client = staticApollo(guestToken)

  try {
    let { data, errors } = await client.mutate({
      mutation: M_DELETE_CART_ITEM,
      variables: { ...values },
      errorPolicy: "all",
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

/**
 *
░█▀▀█ ░█▀▀▀█ ░█▄─░█ ░█▀▀▀ ▀█▀ ░█▀▀█ ░█▀▄▀█ 　 ░█▀▀█ ─█▀▀█ ░█──░█ ░█▀▄▀█ ░█▀▀▀ ░█▄─░█ ▀▀█▀▀
░█─── ░█──░█ ░█░█░█ ░█▀▀▀ ░█─ ░█▄▄▀ ░█░█░█ 　 ░█▄▄█ ░█▄▄█ ░█▄▄▄█ ░█░█░█ ░█▀▀▀ ░█░█░█ ─░█──
░█▄▄█ ░█▄▄▄█ ░█──▀█ ░█─── ▄█▄ ░█─░█ ░█──░█ 　 ░█─── ░█─░█ ──░█── ░█──░█ ░█▄▄▄ ░█──▀█ ─░█──
 * @param {*} values
 * @returns
 */
export const F_M_CONFIRM_PAYMENT = async (values) => {
  let accessToken = Cookies.get(`${process.env.ACCESS_TOKEN}`)
  let guestToken = Cookies.get(`${process.env.GUEST_TOKEN}`)
  let client
  if (accessToken) client = staticApollo(accessToken)
  else client = staticApollo(guestToken)
  try {
    let { data, errors } = await client.mutate({
      mutation: M_CONFIRM_PAYMENT,
      variables: { ...values },
      errorPolicy: "all",
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

export const F_M_CONTACT_FORM = async (values) => {
  let accessToken = Cookies.get(`${process.env.ACCESS_TOKEN}`)
  let guestToken = Cookies.get(`${process.env.GUEST_TOKEN}`)
  let client
  if (accessToken) client = staticApollo(accessToken)
  else client = staticApollo(guestToken)
  try {
    let { data, errors } = await client.mutate({
      mutation: M_CONTACT_FORM,
      variables: { ...values },
      errorPolicy: "all",
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

export const F_M_FORGOT_PASSWORD = async (values) => {
  let guestToken = Cookies.get(`${process.env.GUEST_TOKEN}`)
  let client = staticApollo(guestToken)
  try {
    let { data, errors } = await client.mutate({
      mutation: M_FORGOT_PASSWORD,
      variables: { ...values },
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

export const F_M_UPDATE_FORGOT_PASSWORD = async (values) => {
  let guestToken = Cookies.get(`${process.env.GUEST_TOKEN}`)
  let client = staticApollo(guestToken)
  try {
    let { data, errors } = await client.mutate({
      mutation: M_FORGOTTEN_PASSWORD,
      variables: { ...values },
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

export const F_M_REMIND_ME = async (values) => {
  let accessToken = Cookies.get(`${process.env.ACCESS_TOKEN}`)
  let guestToken = Cookies.get(`${process.env.GUEST_TOKEN}`)
  let client
  if (accessToken) client = staticApollo(accessToken)
  else client = staticApollo(guestToken)
  try {
    let { data, errors } = await client.mutate({
      mutation: M_REMIND_ME,
      variables: { ...values },
      errorPolicy: "all",
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

export const F_M_REPAYMENT = async (values) => {
  let accessToken = Cookies.get(`${process.env.ACCESS_TOKEN}`)
  let guestToken = Cookies.get(`${process.env.GUEST_TOKEN}`)
  let client
  if (accessToken) client = staticApollo(accessToken)
  else client = staticApollo(guestToken)
  try {
    let { data, errors } = await client.mutate({
      mutation: M_REPAYMENT,
      variables: { ...values },
    })

    if (data) {
      return { data }
    } else {
      return { errors }
    }
  } catch (error) {
    return { errors: error }
  }
}

export const F_M_ADD_NOTIFY = async (values, token) => {
  const client = staticApollo(token)
  try {
    let { data, errors } = await client.mutate({
      mutation: M_ADD_NOTIFY,
      variables: { ...values },
      errorPolicy: "all",
    })
    if (data) return { data }
    else return { errors }
  } catch (error) {
    return { errors: error }
  }
}

export const F_M_CREATE_ORDER_V2 = async (values, token, type) => {
  const client = staticApollo(token)
  try {
    let { data, errors } = await client.mutate({
      mutation:
        type === "billing" ? M_CREATE_ORDER_WITH_BILLING : M_CREATEA_ORDER,
      variables: { ...values },
      errorPolicy: "all",
    })
    if (data) return { data }
    else return { errors: errors }
  } catch (error) {
    return { errors: error }
  }
}
