import React, { useEffect, useLayoutEffect, useContext } from "react"
// import { navigate } from "gatsby"
import { useRouter } from "next/router"

import { Navbar, Footer, Loader } from "components/anti"
import NavbarWrapper from "./anti/navbar/navbar-wrapper"
import { LoadingContext } from "context/loading-context"
import { useDispatch, useSelector } from "react-redux"
import { SET_BACKGROUND_TYPE, SET_LOCATIONS } from "lib/redux/types"

const listUrlSticky = [
  "/account",
  "/about",
  "/carts",
  "/checkout",
  "/checkout2",
]

const Layout = ({ children }) => {
  const router = useRouter()

  const navigate = (to) => {
    router.push(to)
  }

  // Mobile viewport height workaround
  // prevent quirky behaviour on mobile when cover is set to 100vh
  useEffect(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }, [])

  const dispatch = useDispatch()

  const { banner_type } = useSelector((state) => state.general)

  const [background, setBackground] = React.useState(null)

  const handleScroll = () => {
    const position = window.pageYOffset

    setBackground(null)

    if (position > 2) dispatch({ type: SET_BACKGROUND_TYPE, payload: "light" })
    else {
      dispatch({
        type: SET_BACKGROUND_TYPE,
        payload: "light",
      })
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [banner_type])

  // Transition link
  // for smooth transition effect
  const useIsomorphicLayoutEffect =
    typeof window !== "undefined" ? useLayoutEffect : useEffect

  useIsomorphicLayoutEffect(() => {
    const wrapper = document.querySelector("main")
    wrapper.classList.add("animated", "fadeIn")

    let dynamicUrl = router.asPath.split("/")
    if (
      listUrlSticky.includes(router.asPath) ||
      (dynamicUrl.length > 3 && dynamicUrl[1] === "category")
    ) {
      document.documentElement.classList.add("enable-sticky")
    } else {
      document.documentElement.classList.remove("enable-sticky")
    }
  }, [])

  useEffect(() => {
    const wrapper = document.querySelector("main")
    const anchor = document.querySelectorAll("a")
    anchor.forEach((item) => {
      const navigation = (e) => {
        const redirect = item.getAttribute("href")
        const currentLoc = window.location.pathname
        e.preventDefault()
        if (currentLoc !== redirect) {
          wrapper.classList.remove("fadeIn")
          wrapper.classList.add("fadeOut")
          setTimeout(() => {
            navigate(redirect)
          }, 250)
        }
      }
      if (item.getAttribute("href") && !item.getAttribute("target")) {
        item.addEventListener("click", navigation)
      }
    })
    return () => {
      anchor.forEach((item) => {
        const navigation = (e) => {
          const redirect = item.getAttribute("href")
          const currentLoc = window.location.pathname
          e.preventDefault()
          if (currentLoc !== redirect) {
            wrapper.classList.remove("fadeIn")
            wrapper.classList.add("fadeOut")
            setTimeout(() => {
              navigate(redirect)
            }, 250)
          }
        }
        if (item.getAttribute("href") && !item.getAttribute("target")) {
          item.removeEventListener("click", navigation)
        }
      })
    }
  }, [])

  return (
    <>
      <NavbarWrapper />
      <main>
        {children}
        <Footer />
      </main>
    </>
  )
}

export default Layout
