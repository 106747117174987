// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be used flexibly. But if you REALLY MUST update edit the component,
// please add `-custom` on the change log file (e.g. `1.0.0-custom.md`) and log the changes

// ========================================================================================================================================
// Breadcrumb
// ========================================================================================================================================
import React from "react"
import PropTypes from "prop-types"

import { Link } from "components/anti"
import { useRouter } from "next/router"
import { parsePath } from "./utils"

export const Breadcrumb = ({ id, path, theme, className, linkData }) => {
  const { locale } = useRouter()
  const pathNames = typeof path === "string" && path.split("/")

  const breadcrumbTheme = () => {
    return `breadcrumb-${theme}`
  }

  return (
    <ol
      id={id}
      className={`breadcrumb ${breadcrumbTheme()} ${className}`}
      data-testid="breadcrumb"
    >
      {linkData ? (
        <>
          {linkData.map((item, i) => (
            <li className="breadcrumb-item" key={i}>
              <Link to={item.url}>{item.text}</Link>
            </li>
          ))}
        </>
      ) : (
        <>
          {typeof path === "string" &&
            pathNames.map((item, index) => {
              if (index === 0) {
                return (
                  <li className="breadcrumb-item" key={`path-home`}>
                    <Link to="/">{locale === "id" ? "Beranda" : "Home"}</Link>
                  </li>
                )
              } else if (index === pathNames.length - 1 && item !== "") {
                return (
                  <li
                    className="breadcrumb-item"
                    key={`path-${item.split("-").join(" ")}`}
                  >
                    {item.split("-").join(" ")}
                  </li>
                )
              } else if (item === "") {
                return null
              } else {
                let url = pathNames
                  .slice(0, index + 1)
                  .join("/")
                  .split(" ")
                  .join("-")
                  .toLowerCase()
                return (
                  <li
                    className="breadcrumb-item"
                    key={`path-${item.split("-").join(" ")}`}
                  >
                    <Link to={url}>{item.split("-").join(" ")}</Link>
                  </li>
                )
              }
            })}
          {typeof path === "object" &&
            path.map((item, index) => {
              if (index === 0) {
                return (
                  <li className="breadcrumb-item" key={`path-home`}>
                    <Link to="/">{item.text}</Link>
                  </li>
                )
              } else if (index === path.length - 1 && item.text !== "") {
                return (
                  <li
                    className="breadcrumb-item"
                    key={`path-${item.text.split(" ").join("-")}`}
                  >
                    {item.text}
                  </li>
                )
              } else if (item === "") {
                return null
              } else {
                return (
                  <li
                    className="breadcrumb-item"
                    key={`path-${item.text.split(" ").join("-")}`}
                  >
                    <Link to={item.url}>{item.text}</Link>
                  </li>
                )
              }
            })}
        </>
      )}
    </ol>
  )
}

Breadcrumb.propTypes = {
  id: PropTypes.string,
  // path: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]), // Set cover theme
  className: PropTypes.string,
}

Breadcrumb.defaultProps = {
  theme: "light",
  className: "",
}
