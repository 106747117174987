import React from "react"

//Components
import { Modal, Button, Image, Input, DatePicker, Check } from "components/anti"
import { ModalBs } from "components/anti/modal/modal-bootstrap"

//Assets
import StockImg from "@public/img/sample/stock.png"
import DocumentImg from "@public/img/sample/document.png"
import ModalAuthImg from "@public/img/sample/modal-auth.png"
import { useScrollAnim } from "components/hooks/hooks"

//Utils
import { useRouter } from "next/router"
import { F_M_LOGIN, F_M_REGISTER } from "lib/function"
import Cookies from "js-cookie"
import * as Yup from "yup"
import { Formik } from "formik"
import { SET_SHOW_TOAST, SET_TEXT_TOAST, SET_TYPE_TOAST } from "lib/redux/types"
import { useDispatch } from "react-redux"

const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/

/**
 * @param {*} param0
 * @returns
 */
export const ModalRegister = ({ id, show, hide, callback }) => {
  //States
  const Router = useRouter()
  const dispatch = useDispatch()
  const locale = Router.locale
  const [trigger, anim] = useScrollAnim()

  const YupRegisterValidation = Yup.object({
    email: Yup.string()
      .email(
        locale === "id" ? "Format email tidak valid" : "Invalid email format"
      )
      .required(locale === "id" ? "Email harus diisi" : "Email required"),
    first_name: Yup.string()
      .required(
        locale === "id" ? "Nama Awal harus diisi" : "First name required"
      )
      .test("len", "Minimal 3 character alphabet", (val) => val?.length >= 3)
      .matches(
        /^[a-zA-Z\s]*$/,
        "Invalid Characters. Please use only alphabets to fill your name"
      ),
    last_name: Yup.string()
      .required(
        locale === "id" ? "Nama Akhir harus diisi" : "Last name required"
      )
      .test("len", "Minimal 3 character alphabet", (val) => val?.length >= 3)
      .matches(
        /^[a-zA-Z\s]*$/,
        "Invalid Characters. Please use only alphabets to fill your name"
      ),
    password: Yup.string()
      .required(
        locale === "id" ? "Kata sandi harus diisi" : "Password required"
      )
      .matches(
        passwordRegex,
        locale === "id"
          ? "Password harus memiliki minimal 8 karakter dan minimal 1 angka"
          : "Password must have a minimum of 8 characters and at least 1 number"
      ),
    password_confirmation: Yup.string()
      .required(
        locale === "id" ? "Kata sandi tidak cocok" : "Password does not match"
      )
      .oneOf(
        [Yup.ref("password"), null],
        locale === "id" ? "kata sandi harus cocok" : "Passwords must match"
      ),
    date: Yup.date().required(
      locale === "id" ? "Tanggal lahir harus diisi" : "Date of birth required"
    ),
  })

  const initialState = {
    date: "",
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    password_confirmation: "",
  }

  const [state, setState] = React.useState({
    isLoading: false,
    error: null,
  })

  //Functions
  const handleChangeDate = (e) => {
    return `${new Date(e).getFullYear()}-${
      new Date(e).getMonth() + 1
    }-${new Date(e).getDate()}`
  }

  const handleSubmit = async (values) => {
    setState((prev) => ({ ...prev, isLoading: true, error: null }))
    let stringData = handleChangeDate(values.date)
    let name = `${values.first_name} ${values.last_name}`
    let { email, password, password_confirmation } = values
    let { data, errors } = await F_M_REGISTER({
      email,
      name,
      password,
      password_confirmation,
      dob: stringData,
    })

    if (data) {
      if (data.register.status === "SUCCESS") {
        setState((prev) => ({ ...prev, isLoading: false }))
        handleToast(
          "success",
          locale === "id"
            ? "Akun berhasil dibuat! Silakan coba masuk sekarang."
            : "Account successfully created! Please try sign in now."
        )
        hide()
      } else {
        setState((prev) => ({
          ...prev,
          isLoading: false,
          error: data.register.message,
        }))
      }
    }
  }

  const handleToast = (type, message, timeout = 3000) => {
    dispatch({ type: SET_SHOW_TOAST, payload: true })
    dispatch({ type: SET_TYPE_TOAST, payload: type })
    dispatch({ type: SET_TEXT_TOAST, payload: message })
    setTimeout(
      () => dispatch({ type: SET_SHOW_TOAST, payload: false }),
      timeout
    )
  }
  return (
    // <Modal
    //   className="modal-md"
    //   id={id}
    //   isShowing={show}
    //   hide={hide}
    //   contentClassName="p-0 m-0 scrolled"
    // >
    <ModalBs
      className="modal-md"
      id={id}
      isShowing={show}
      hide={() => {
        hide()
        setState((prev) => ({ ...prev, isLoading: false, error: null }))
      }}
      contentClassName="p-0 m-0 scrolled"
    >
      <div className="modal-auth" ref={trigger}>
        <div className={`p-4 ${anim(1)}`}>
          <h3 className="title">
            {locale === "id" ? "Pelanggan baru" : "New Customer"}
          </h3>
          <p className="subtitle">
            {locale === "id"
              ? "Jika Anda pelanggan baru, silakan mendaftar menggunakan formulir di bawah ini untuk checkout lebih cepat."
              : "If you're a new customer, please sign up using the form below for faster checkout."}
          </p>

          <Formik
            initialValues={initialState}
            validationSchema={YupRegisterValidation}
            onSubmit={handleSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-row row-2">
                  <div className="form-group col-12">
                    <Input
                      type="email"
                      name="email"
                      label="Email"
                      placeholder={
                        locale === "id"
                          ? "Masukkan email Anda"
                          : "Insert your email"
                      }
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      value={values.email}
                      formText="We will send your order confirmation here."
                    />
                  </div>
                  <div className="form-group col-12 col-md-6">
                    <Input
                      name="first_name"
                      label={locale === "id" ? "Nama Depan" : "First Name"}
                      placeholder={
                        locale === "id"
                          ? "Masukkan nama depan Anda"
                          : "Insert your first name"
                      }
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      value={values.first_name}
                    />
                  </div>
                  <div className="form-group col-12 col-md-6">
                    <Input
                      name="last_name"
                      label={locale === "id" ? "Nama Keluarga" : "Last Name"}
                      placeholder={
                        locale === "id"
                          ? "Masukkan nama belakang Anda"
                          : "Insert your last name"
                      }
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      value={values.last_name}
                    />
                  </div>
                  <div className="fomr-group col-12">
                    <div className="field">
                      <div>
                        <DatePicker
                          name="date"
                          wrapperClassName="w-100"
                          placeholder="DD/MM/YYYY"
                          onChange={(e) => setFieldValue("date", e)}
                          selected={values.date}
                          errors={errors}
                          touched={touched}
                          label={
                            locale === "id" ? "Tanggal Lahir" : "Date of Birth"
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-12 mt-3">
                    <Input
                      name="password"
                      label="Password"
                      placeholder={
                        locale === "id"
                          ? "Masukkan kata sandi Anda"
                          : "Insert your password"
                      }
                      type="password"
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      value={values.password}
                    />
                  </div>
                  <div className="form-group col-12">
                    <Input
                      name="password_confirmation"
                      label={
                        locale === "id"
                          ? "Konfirmasi Kata Sandi"
                          : "Confirm Password"
                      }
                      placeholder={
                        locale === "id"
                          ? "Konfirmasi password Anda"
                          : "Confirm your password"
                      }
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      value={values.password_confirmation}
                      type="password"
                    />
                  </div>
                  {state.error && (
                    <p className="error-text mt-2 mb-0 px-2 text-capitalize">
                      {state.error}
                    </p>
                  )}
                </div>

                <div className="btn-submit-wrapper mt-4">
                  <Button
                    loader
                    className={`w-100 ${state.isLoading && "loading"}`}
                    type="submit"
                    variant="primary"
                    // onClick={handleSubmit}
                  >
                    {locale === "id" ? "Buat Akun" : "Create Account"}
                  </Button>
                </div>

                <div className="register-new-customer mt-4">
                  <p className="text-body-3 text-center">
                    {locale === "id" ? "Punya akun?" : "Have an account?"}{" "}
                    <span
                      onClick={() => callback()}
                      className="hover-underline inverse pb-2"
                      style={{ cursor: "pointer" }}
                    >
                      {locale === "id" ? "Klik disini" : "Click Here"}
                    </span>
                  </p>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </ModalBs>
    // </Modal>
  )
}
