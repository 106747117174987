import EnhancedTextField from "@components/anti/forms/react-hook-form/text-field"
import { useClickOutside } from "@components/hooks/hooks"
import { useRouter } from "next/router"
import React, { useRef } from "react"

const SearchMenu = ({ handleSearch, navExpand, search }) => {
  const ref = useRef(null)
  const resultUrl = "/result"

  //Utils
  const Router = useRouter()
  const { locale } = Router

  //States
  const [state, setState] = React.useState({
    searchType: "",
  })

  //Functions
  const handleEnterSearch = (e) => {
    if (e.key === "Enter") {
      handleSearch()
      Router.push(`${resultUrl}?type=${state.searchType}`)
    }
  }

  useClickOutside(ref, () => {
    if (search.clicked) handleSearch()
  })

  return (
    <div className={`search-nav-mobile d-${navExpand}-none`} ref={ref}>
      <EnhancedTextField
        onChange={(e) =>
          setState((prev) => ({ ...prev, searchType: e.target.value }))
        }
        id="search"
        name="search"
        variant="underline"
        placeholder={locale === "id" ? "Cari disini..." : "Search here..."}
        icon={{
          position: "adornment-end",
          el: (
            <i
              className="air ai-search"
              onClick={() => {
                handleSearch()
                Router.push(`${resultUrl}?type=${state.searchType}`)
              }}
            ></i>
          ),
        }}
        onKeyPress={handleEnterSearch}
        inputClassName="form-search white"
      />
    </div>
  )
}

export default SearchMenu
