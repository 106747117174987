import React from "react"
import PaginationMui from "@material-ui/lab/Pagination"
import { createTheme, ThemeProvider } from "@material-ui/core"
import { useWindowSize } from "@components/hooks/hooks"

const theme = createTheme({
  typography: {
    fontFamily: ["Zichtbaar"],
  },
})

export const Pagination = ({ count, page, onChange }) => {
  const [paging, setPaging] = React.useState(page ?? 1)
  const { width } = useWindowSize()
  const isMobile = width < 768
  const handleChange = (event, value) => {
    if (onChange) onChange(value)
    setPaging(value)
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="pagination-custom">
        <PaginationMui
          count={count}
          page={paging}
          onChange={handleChange}
          showFirstButton
          showLastButton
          variant="outlined"
          shape="rounded"
          // boundaryCount={isMobile ? 0 : 3}
          // siblingCount={isMobile ? 0 : 3}
        />
      </div>
    </ThemeProvider>
  )
}
